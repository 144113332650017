import React, { useState, useEffect, useCallback } from 'react';

const LoginComponentBancoCora = ({ data, dataProvider, setModalValue, notify, refresh }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [showContent, setShowContent] = useState(false);
	const [credentials, setCredentials] = useState({
		username: '',
		password: '',
	});

	useEffect(() => {
		const timeout = setTimeout(() => setShowContent(true), 100);
		return () => clearTimeout(timeout);
	}, []);

	const handleInputChange = useCallback((e) => {
		const { name, value } = e.target;
		setCredentials((prev) => ({
			...prev,
			[name]: value,
		}));
	}, []);

	const handleSubmit = useCallback(
		async (e) => {
			e.preventDefault();
			if (isLoading) return;

			setIsLoading(true);

			try {
				const updatedData = {
					...data,
					...credentials,
				};

				const response = await dataProvider.safeCreate('remessas/download', {
					data: updatedData,
				});

				const responseData = response?.data;
				if (!responseData) {
					notify('Erro inesperado, tente recarregar a página', 'warning');
					return;
				}

				if (typeof responseData === 'object') {
					const { boletos_gerados_por_api_do_banco } = responseData;
					if (boletos_gerados_por_api_do_banco) {
						notify(
							'Boletos estão sendo gerados pela API do banco, por favor aguarde alguns momentos enquanto o banco os processa'
						);
						refresh();
						setModalValue((v) => ({ ...v, open: false }));
					}
				}
			} catch (error) {
				if ([401, 403].includes(error?.response?.status)) {
					return Promise.reject(error);
				}

				const errorMessage =
					Object.values(error?.response?.data || {})[0] || 'Erro inesperado, tente recarregar a página';
				notify(errorMessage, 'warning');
			} finally {
				setIsLoading(false);
			}
		},
		[isLoading, credentials, data, dataProvider, notify, refresh, setModalValue]
	);

	const containerStyles = {
		backgroundColor: '#f0f4f8',
		fontFamily: 'Avenir, sans-serif',
		transition: 'opacity 0.5s ease-in-out',
		opacity: showContent ? 1 : 0,
	};

	const headerStyles = {
		width: '36rem',
		margin: '0 auto',
		paddingTop: '3rem',
		textAlign: 'center',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	};

	const formContainerStyles = {
		width: '28rem',
		margin: '3rem auto 5rem',
		padding: '3rem',
		backgroundColor: 'white',
		boxShadow: '0px 8px 16px rgba(32, 32, 32, 0.06)',
		borderRadius: '1rem',
	};

	const inputStyles = {
		marginTop: '1rem',
		border: '1px solid #f0f4f8',
		borderRadius: '1rem',
		height: '3.2rem',
		padding: '1rem',
		backgroundColor: '#f0f4f8',
		outline: 'none',
		width: '100%',
	};

	return (
		<div style={containerStyles}>
			<div id='kc-header' style={headerStyles}>
				<img
					src={
						process.env.REACT_APP_LOGO_URL ||
						'https://cdn.prod.website-files.com/63b868fdfcde8bd36cb30641/63c04f6e08df7840dc74a17b_cora-logo.png'
					}
					alt='Logo Banco Cora'
					style={{ display: 'block', width: '50%', height: '100%' }}
				/>
			</div>

			<div id='kc-content'>
				<div id='kc-content-wrapper' style={formContainerStyles}>
					<form onSubmit={handleSubmit}>
						<p
							style={{
								marginTop: 0,
								fontSize: '1.5rem',
								textAlign: 'center',
							}}
						>
							Faça login na Cora
						</p>

						<div>
							<input
								tabIndex={1}
								placeholder='CPF'
								id='username'
								name='username'
								type='text'
								autoFocus
								autoComplete='off'
								style={inputStyles}
								value={credentials.username}
								onChange={handleInputChange}
							/>
						</div>

						<div>
							<input
								tabIndex={2}
								placeholder='Senha'
								id='password'
								name='password'
								type='password'
								autoComplete='off'
								style={inputStyles}
								value={credentials.password}
								onChange={handleInputChange}
							/>
						</div>

						<div id='kc-form-buttons'>
							<input type='hidden' id='id-hidden-input' name='credentialId' />
							<button
								tabIndex={4}
								type='submit'
								disabled={isLoading}
								style={{
									...inputStyles,
									marginTop: '2rem',
									backgroundColor: '#fe3e6d',
									color: 'white',
									cursor: isLoading ? 'not-allowed' : 'pointer',
									height: '4rem',
									transition: 'background-color 0.3s ease',
									':disabled': {
										backgroundColor: '#ccc',
									},
								}}
							>
								{isLoading ? 'Aguarde...' : 'Log In'}
							</button>

							{isLoading && (
								<p
									style={{
										marginTop: '1rem',
										textAlign: 'center',
										color: '#555',
									}}
								>
									Carregando...
								</p>
							)}
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default LoginComponentBancoCora;
