import React, { useContext, useEffect } from 'react';

import { TextField, useListContext, TopToolbar } from 'react-admin';

import { CondominiosContext } from '../../../../../context/CondominioContextProvider';
import { ModalSizeEditableContextProvider } from '../../../../common/ModalSizeEditableContext';
import { Tabela } from '../../../../common/Tabela';
import { Padrao } from '../../../../common/filtros/Padrao';
import { Autocompletar } from '../../../../common/filtros/Autocompletar';
import { Data } from '../../../../common/filtros/DataV2';
import { ArrecadacoesContext } from '../../ArrecadacoesContext';
import ContasReceberArrecadacoesList, {
	ResponsavelField,
	TipoResponsavelField,
	DateField,
	ContaReceberNumberField,
} from '../../ContasReceberArrecadacoesList';
import RemessasContextProvider from './provedoresContexto/RemessasContextProvider';

const TabelaContasReceberRemessas = (props) => {
	const { onUnselectItems } = useListContext();
	const { referencia, id_identificacao_arrecadacao } = useContext(ArrecadacoesContext);
	const { condominioSelecionado } = useContext(CondominiosContext);

	useEffect(onUnselectItems, [condominioSelecionado, referencia, id_identificacao_arrecadacao]);

	return (
		<Tabela {...props} disableClickRow>
			<TextField source='nome_grupo_unidade' label='Grupo' filtro={<Padrao />} />
			<TextField source='nome_unidade' label='Unidade' filtro={<Padrao />} />
			<ResponsavelField source='pagador_nome' label='Responsável' filtro={<Padrao />} />
			<TipoResponsavelField
				source='tipo_responsavel'
				label='Cobrança'
				filtro={
					<Autocompletar
						opcoes={[
							{ id: 'P', name: 'Proprietário' },
							{ id: 'I', name: 'Inquilino' },
						]}
					/>
				}
			/>
			<DateField source='data_vencimento' label='Vencimento' filtro={<Data />} />
			<TextField source='nosso_numero' label='Nosso Número' filtro={<Padrao />} />
			<ContaReceberNumberField source='valor_pendente' label='Pendente' align='right' filtro={<Padrao />} />
		</Tabela>
	);
};

const BulkActionContasReceberRemessas = () => (
	<TopToolbar style={{ padding: 0, alignSelf: 'flex-end' }}>
		<ModalSizeEditableContextProvider customDialogProps={{ minWidth: '61vw' }}>
			<RemessasContextProvider />
		</ModalSizeEditableContextProvider>
	</TopToolbar>
);

const labelRowsPerPagePadrao = 'Contas à receber disponíveis para gerar remessas';
const labelRowsPerPageAPI = 'Contas à receber disponíveis para gerar boletos';

const labelRowsPerPagePorInstituicaoTipoIntegracao = {
	['364-0']: labelRowsPerPageAPI,
	['077-0']: labelRowsPerPageAPI,
	['748-0']: labelRowsPerPageAPI,
	['756-0']: labelRowsPerPageAPI,
	['133-0']: labelRowsPerPageAPI,
	['450-0']: labelRowsPerPageAPI,
	['001-0']: labelRowsPerPageAPI,
	['332-0']: labelRowsPerPageAPI,
	['403-0']: labelRowsPerPageAPI,
};

const useFiltrosContasReceberRemessas = () => {
	const { condominioSelecionado } = useContext(CondominiosContext);
	const { id_identificacao_arrecadacao, referencia, contaBDestino } = useContext(ArrecadacoesContext);

	const condominioSelecionadoId = condominioSelecionado?.id;

	const filtros = React.useMemo(
		() => ({
			condominio_id: condominioSelecionadoId,
			identificacao_id: id_identificacao_arrecadacao,
			referencia__year: referencia?.split('-')[0] || '',
			referencia__month: referencia?.split('-')[1] || '',
			conta_b_destino_id: contaBDestino?.id,
		}),
		[condominioSelecionadoId, id_identificacao_arrecadacao, referencia, contaBDestino]
	);

	return filtros;
};

const ContasReceberRemessasList = (props) => {
	const { contaBDestino } = useContext(ArrecadacoesContext);
	const filtros = useFiltrosContasReceberRemessas();
	const { codigo_instituicao_financeira, codigo_tipo_integracao } = contaBDestino || {};

	return (
		<ContasReceberArrecadacoesList
			{...props}
			sort={{ field: 'nome_unidade', order: 'ASC' }}
			filter={filtros}
			actions={<BulkActionContasReceberRemessas />}
			Table={<TabelaContasReceberRemessas />}
			labelRowsPerPage={
				labelRowsPerPagePorInstituicaoTipoIntegracao[
					`${codigo_instituicao_financeira}-${codigo_tipo_integracao}`
				] || labelRowsPerPagePadrao
			}
			resource='contas_receber_remessas'
			style={{ marginTop: '-63px' }}
		/>
	);
};

export default ContasReceberRemessasList;
