import memoize from '../../../../../../common/memoize';

import TabInformacao from '../../../tabs/TabInformacao';
import TabEmissaoBoletos from '../../../tabs/TabEmissaoBoletos';

import { getFormatarOptions } from '../../../utils/functions';

import { getValidate, getFormataPayloadValues } from '../../default/ContaCorrenteProps';

import formatarInitialValues from './formatarInitialValues';
import {
	createOnChangeTipoPadraoMulta,
	getPropsOnChangeTipoPadraoMulta,
} from './componentes/TabInstrucoesCobrancaRemessa/MultaFields';
import {
	createOnChangeTipoPadraoJuros,
	getPropsOnChangeTipoPadraoJuros,
} from './componentes/TabInstrucoesCobrancaRemessa/JurosFields';
import {
	createOnChangeTipoPadraoDesconto,
	getPropsOnChangeTipoPadraoDesconto,
} from './componentes/TabInstrucoesCobrancaRemessa/DescontoFields';
import TabConta from './componentes/TabConta';
import TabInstrucoesCobrancaRemessa from './componentes/TabInstrucoesCobrancaRemessa';
import TabInstrucoesCobrancaBoleto from './componentes/TabInstrucoesCobrancaBoleto';
import validacoes from './validacoes';
import formatadoresPayloadValues from './formatadoresPayloadValues';
import ModalCadastrarDadosIntegracaoAPI from './componentes/TabConta/ModalCadastrarDadosIntegracaoAPI';
import { getDadosFormattingContaCorrente } from '../../../utils/formaters_parses';

const getExtras = ({
	props,
	instituicaoCodigo,
	initialDados: {
		opcoesDadosBancarios: { integracoes },
	},
}) => ({
	optionsIsencoes: integracoes[instituicaoCodigo].optionsIsencoes,
	...props,
});

const getParseDescontoDias = () => (value) =>
	(typeof value !== 'number' && !value) || isNaN(value) ? '' : parseInt(value);

const createOnChangeTipoLimitePagamento =
	({ sources: { sourceDias } = {}, optionsIsencoesTipo }, change) =>
	(_, tipoValue, input) => {
		input.onChange(tipoValue);
		if (!tipoValue || optionsIsencoesTipo[tipoValue.id]) {
			change(sourceDias, undefined);
		}
	};

const ContaCorrenteProps = {
	instituicaoCodigo: '077',
	getDisabledTabs: memoize(({ values: { emitir_boletos } }) => ({
		disabled0: false,
		disabled1: false,
		disabled2: !emitir_boletos,
		disabled3: !emitir_boletos,
		disabled4: !emitir_boletos,
	})),
	tabContents: {
		TabInformacao,
		TabEmissaoBoletos,
		TabConta,
		TabInstrucoesCobrancaRemessa,
		TabInstrucoesCobrancaBoleto,
	},
	props: {
		tipo_conta: {
			source: 'tipo_conta_bancaria',
			name: 'tipo_conta_bancaria',
			id: 'tipo_conta_bancaria',
			choices: [{ id: 'CC', text: 'Conta Corrente' }],
		},
		conta_corrente: {
			...getDadosFormattingContaCorrente(5),
		},
		multa: {
			getPropsOnChangeTipo: getPropsOnChangeTipoPadraoMulta,
			createOnChangeTipo: createOnChangeTipoPadraoMulta,
		},
		juros: {
			getPropsOnChangeTipo: getPropsOnChangeTipoPadraoJuros,
			createOnChangeTipo: createOnChangeTipoPadraoJuros,
		},
		desconto: {
			getPropsOnChangeTipo: getPropsOnChangeTipoPadraoDesconto,
			createOnChangeTipo: createOnChangeTipoPadraoDesconto,
		},
		limite_pagamento_dias: {
			min: 0,
			max: 60,
		},
		limite_pagamento: {
			createOnChangeTipo: createOnChangeTipoLimitePagamento,
		},
		desconto_dias: { getParseDias: getParseDescontoDias },
		CadastrarDadosIntegracaoAPIButton: {
			ModalCadastrarDadosIntegracaoAPI,
			getInicialCallback: ({ errorsMsg, ...inicialDados }) => ({
				...inicialDados,
				errorsMsg,
				initialValues: {
					client_id: '',
					client_secret: '',
					certificado_api: null,
				},
				values: {
					client_id: '',
					client_secret: '',
					certificado_api: null,
				},
				errors: {
					client_id: errorsMsg.client_id.required,
					client_secret: errorsMsg.client_secret.required,
					certificado_api: errorsMsg.certificado_api.required,
				},
			}),
		},
	},
	getOptions: getFormatarOptions('077'),
	getFormataPayloadValues,
	getValidate,
	formatarInitialValues,
	validacoes,
	formatadoresPayloadValues,
	getExtras,
};

export default ContaCorrenteProps;
