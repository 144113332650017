import {
	formataPayloadValuesDesconto,
	formataPayloadValuesInstrucoesComTipoValorDias,
	getFormatadorPayloadCobrancaConfig,
	getPadraoPayloadValuesInstrucoesRemessa,
} from '../../default/ContaCorrenteProps/getFormataPayloadValues';

import {
	defaultFormatadoresPayloadValues,
	defaultFormatadoresPayloadValuesCobrancaConfig,
	formataPayloadValuesTabContaCobrancaConfig,
} from '../../default/ContaCorrenteProps/getFormataPayloadValues';

const formataPayloadValuesMulta = (props) =>
	formataPayloadValuesInstrucoesComTipoValorDias({
		...props,
		field: 'multa',
		valorIsensao: 'ISE',
	});

const formataPayloadValuesJuros = (props) =>
	formataPayloadValuesInstrucoesComTipoValorDias({
		...props,
		field: 'juros',
		valorIsensao: 'JIS',
	});

const formataPayloadValuesSacadorAvalista = () => ({ sacador_avalista: undefined });

const formataPayloadValuesTabInstrucoesCobrancaRemessa = ({ values }) => ({
	...getPadraoPayloadValuesInstrucoesRemessa(),
	...formataPayloadValuesMulta({ values }),
	...formataPayloadValuesJuros({ values }),
	...formataPayloadValuesDesconto({ values }),
});

const formatadoresPayloadValues = {
	...defaultFormatadoresPayloadValues,

	cobranca_config: getFormatadorPayloadCobrancaConfig({
		formatadores: [
			...defaultFormatadoresPayloadValuesCobrancaConfig,
			formataPayloadValuesTabContaCobrancaConfig,
			formataPayloadValuesTabInstrucoesCobrancaRemessa,
			formataPayloadValuesSacadorAvalista,
		],
	}),
};

export default formatadoresPayloadValues;
