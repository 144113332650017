import React, { useContext, useEffect, useRef, useState } from 'react';

import { useDataProvider, useListContext, useNotify, TextField, FunctionField } from 'react-admin';
import ImageRoundedIcon from '@material-ui/icons/ImageRounded';

import LeiturasListContext from './LeiturasListContext';
import ModalConfirmarLeituraAtualLTLeituraAnterior from './modais/ModalConfirmarLeituraAtualLTLeituraAnterior';
import { BRLNumberField } from '../../common/BRLNumberField';
import CurrencyInput from '../../common/CurrencyInput';
import { Padrao } from '../../common/filtros/Padrao';
import { ModalContext, ModalContextProvider, CustomDialog } from '../../common/ModalContext';
import { Tabela, TabelaRowContext } from '../../common/Tabela';
import { Tooltip, Typography } from '@material-ui/core';
import ImagemLeitura from './modais/componentes/ImagemLeitura';

const LancamentoLeituraNumberField = (props) => (
	<BRLNumberField
		options={{
			minimumFractionDigits: props.source === 'valor_da_leitura' ? 2 : 6,
		}}
		{...props}
	/>
);

const LeituraInput = (props) => {
	const dataProvider = useDataProvider();
	const {
		filterValues: { leitura_id, referencia },
		ids,
		perPage,
		setPage,
		page,
		data,
		loading,
		total,
	} = useListContext();
	const notify = useNotify();
	const [lancamento_id, setLancamento_id] = useState(props.record?.lancamento_id);
	const { dataLeituraParaLancamentoLeitura, leituraSituacao } = useContext(LeiturasListContext);
	const { rowRecord, setRowRecord } = useContext(TabelaRowContext);
	const { setModalValue } = useContext(ModalContext);
	const reloadedRef = useRef(loading);
	const [lancandoLeitura, setLancandoLeitura] = useState(false);

	useEffect(() => {
		reloadedRef.current = !loading ? true : reloadedRef.current;
	}, [loading]);

	const update = () => {
		if (reloadedRef.current && data && data[rowRecord.id]) {
			const newRowRecord = data[rowRecord.id];
			setRowRecord(newRowRecord);
			setLancamento_id(newRowRecord.lancamento_id);
			reloadedRef.current = false;
		}
	};

	useEffect(update, [data]);

	const handleChange = (e, values, record, source, resource, ids, perPage, total, setPage) => {
		e.preventDefault();
		const target = e.target;
		const data_leitura =
			dataLeituraParaLancamentoLeitura.current && `${dataLeituraParaLancamentoLeitura.current}` !== 'Invalid Date'
				? dataLeituraParaLancamentoLeitura.current.toISOString().split('T')[0]
				: null;
		const functionLancarLeitura = () => {
			setLancandoLeitura(true);
			if (lancandoLeitura) return;
			(lancamento_id
				? values[source]
					? dataProvider.update(resource, {
							id: lancamento_id,
							data: {
								leitura: values[source] || 0,
								data_leitura,
							},
					  })
					: dataProvider.delete(resource, {
							id: lancamento_id,
					  })
				: dataProvider.create(resource, {
						data: {
							leitura: values[source] || 0,
							referencia: (typeof referencia === 'object' ? referencia.toISOString() : referencia).split(
								'T'
							)[0],
							id_leitura: leitura_id,
							id_unidade: record.id_unidade,
							data_leitura,
						},
				  })
			)
				.then((response) => {
					const data = response?.data;
					if (data) {
						setRowRecord((RowRecord) => ({
							...RowRecord,
							leitura_atual: data.leitura,
							consumo_atual: data.consumo,
							valor_da_leitura: data.valor,
							lancamento_id: data.id,
						}));
						setLancamento_id(data.id);
						notify('Leitura lançada com sucesso');
						const positionTarget = ids.findIndex((item) => item === target.id.replace(source, ''));

						const input = document.querySelector(`input#${source}${record.id}`);
						const focoAutomatico = input.getAttribute('focoautomatico');
						if (focoAutomatico) {
							input.removeAttribute('focoautomatico');
							if (ids.length === positionTarget + 1) {
								// Se é a última row da página
								if (ids.length === total - (perPage * page - perPage)) {
									// Se a página contêm todas as últimas rows disponíveis
									const firstInput = document.querySelector(`input#${source}${ids[0]}`);
									firstInput.focus();
								} else {
									setPage(page + 1);
								}
							} else {
								const nextInput = document.querySelector(`input#${source}${ids[positionTarget + 1]}`);
								if (nextInput) nextInput.focus();
							}
						}
					} else {
						notify('Leitura zerada com sucesso');
						setRowRecord((RowRecord) => ({
							...RowRecord,
							leitura_atual: null,
							consumo_atual: null,
							valor_da_leitura: null,
							lancamento_id: null,
						}));
						setLancamento_id(null);
					}
				})
				.catch((e) => {
					if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
					const erroMsg = Object.values(e?.response?.data || {})[0] || 'Erro inesperado';
					notify(erroMsg, 'warning');
				})
				.finally(() => {
					setLancandoLeitura(false);
				});
		};

		if (typeof record.ultima_leitura === 'number' ? record.ultima_leitura > (values[source] || 0) : false) {
			setModalValue((v) => ({
				...v,
				open: true,
				dialogBody: (
					<ModalConfirmarLeituraAtualLTLeituraAnterior functionLancarLeitura={functionLancarLeitura} />
				),
			}));
		} else {
			functionLancarLeitura();
		}
	};

	const onBlur =
		({ values, record, source, resource, ids, perPage, total, setPage }) =>
		(e) => {
			if (values.leitura_atual > 0 && record?.leitura_atual !== values.leitura_atual) {
				handleChange(e, values, record, source, resource, ids, perPage, total, setPage);
			}
		};

	const onKeyDown =
		({ values, record, source, resource, ids, perPage, total, setPage }) =>
		(e) => {
			if (e.key === 'Enter') {
				e.target.setAttribute('focoautomatico', 'true');
				handleChange(e, values, record, source, resource, ids, perPage, total, setPage);
			}
			if (e.key === 'Tab') {
				e.target.setAttribute('focoautomatico', 'true');
			}
		};

	return leituraSituacao === 'A' ? (
		<CurrencyInput
			{...props}
			record={rowRecord}
			ids={ids}
			perPage={perPage}
			total={total}
			setPage={setPage}
			decimalScale={6}
			events={{ onBlur, onKeyDown }}
			isAllowed={({ floatValue }) => {
				return String(floatValue).split('.')[0].length < 10;
			}}
			disabled={lancandoLeitura}
		/>
	) : (
		<LancamentoLeituraNumberField {...props} source='leitura_atual' label='Leitura' />
	);
};

const TabelaLancamentosLeituraPadrao = (props) => {
	const { ids } = useListContext();
	const [focusFirstInputPage, setFocusFirstInputPage] = useState();
	const [showImage, setShowImage] = React.useState(false);
	const [imagePath, setImagePath] = React.useState(null);

	useEffect(() => {
		setFocusFirstInputPage(document.querySelector(`input#leitura_atual${ids[0]}`));
	}, [ids]);

	useEffect(() => {
		if (focusFirstInputPage) focusFirstInputPage.focus();
	}, [focusFirstInputPage]);

	const handleClickImage = (record) => {
		if (record.foto) {
			setImagePath(record.foto);
			setShowImage(true);
		}
	};

	return (
		<ModalContextProvider>
			<Tabela disableClickRow {...props}>
				<TextField source='descricao_tipo' label='Descrição' filtro={<Padrao />} />
				<TextField source='nome_grupo_unidade' label='Grupo' filtro={<Padrao />} />
				<TextField source='unidade' label='Unidade' filtro={<Padrao />} />
				<LancamentoLeituraNumberField align='right' source='penultima_leitura' label='Penúltima Leitura' />
				<LancamentoLeituraNumberField align='right' source='penultimo_consumo' label='Penúltimo Consumo' />
				<LancamentoLeituraNumberField align='right' source='ultima_leitura' label='Última Leitura' />
				<LeituraInput source='leitura_atual' label='Leitura' />
				<LancamentoLeituraNumberField align='right' source='consumo_atual' label='Consumo' />
				<LancamentoLeituraNumberField align='right' source='valor_da_leitura' label='Valor' />
				<FunctionField
					label='Ação'
					render={(record) =>
						record.foto ? (
							<Typography
								onClick={() => handleClickImage(record)}
								style={{ display: 'flex', marginLeft: '7px', cursor: 'pointer' }}
								variant='div'
							>
								<Tooltip title='Visualizar Foto da Leitura' placement='bottom'>
									<ImageRoundedIcon />
								</Tooltip>
							</Typography>
						) : null
					}
				/>
			</Tabela>
			{showImage && <ImagemLeitura imagePath={imagePath} setShowImage={setShowImage} />}

			<CustomDialog />
		</ModalContextProvider>
	);
};

export default TabelaLancamentosLeituraPadrao;
