import React from 'react';

interface ImagemLeituraProps {
	imagePath: string;
	setShowImage: (show: boolean) => void;
}

const ImagemLeitura: React.FC<ImagemLeituraProps> = ({ imagePath, setShowImage }) => {
	return (
		<div
			style={{
				position: 'fixed',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
				backgroundColor: 'rgba(0, 0, 0, 0.8)',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				zIndex: 3,
			}}
			onClick={() => setShowImage(false)}
		>
			<img
				src={imagePath}
				alt='Imagem da Leitura'
				style={{
					width: 'auto',
					height: 'auto',
					maxHeight: '90%',
					maxWidth: '800px',
					borderRadius: '8px',
					boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
				}}
			/>
		</div>
	);
};

export default ImagemLeitura;
