import React from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FormGroup, Checkbox, Box } from '@material-ui/core';

import { BoletosContext } from '../abas/ContasReceberBoletos/BoletosContextProvider';

const BoxConfigDemonstrativoVerso = () => {
	const {
		opcoesImpressao: { imprimir_verso_modelo_carta, imprimir_verso_relatorio },
		handleChangeOpcoesImpressao,
	} = React.useContext(BoletosContext);
	return (
		<Box display='flex' minWidth='0'>
			<FormGroup row>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimir_verso_modelo_carta}
							name='imprimir_verso_modelo_carta'
							onChange={(e, checked) =>
								handleChangeOpcoesImpressao(checked, 'imprimir_verso_modelo_carta')
							}
						/>
					}
					label='Modelo carta'
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimir_verso_relatorio}
							name='imprimir_verso_relatorio'
							onChange={(e, checked) => handleChangeOpcoesImpressao(checked, 'imprimir_verso_relatorio')}
						/>
					}
					label='Imprimir demonstrativo no verso'
				/>
			</FormGroup>
		</Box>
	);
};

export default BoxConfigDemonstrativoVerso;
