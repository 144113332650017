import { getDadosFormattingContaCorrente } from '../../utils/formaters_parses';
import { getFormatarOptions } from '../../utils/functions';
import DefaultContaNaoCorrenteProps, { defaultComponentesProps } from '../default/ContaNaoCorrenteProps';

const ContaNaoCorrenteProps = {
	...DefaultContaNaoCorrenteProps,
	instituicaoCodigo: '084',
	props: {
		...defaultComponentesProps,
		conta_corrente: {
			...getDadosFormattingContaCorrente(7, 1),
		},
	},
	getOptions: getFormatarOptions('084', { agencia: 'agencias_uniprime' }),
};

export default ContaNaoCorrenteProps;
