import React, { useContext } from 'react';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Autocomplete } from '@material-ui/lab';

import { useTheme } from '@material-ui/core/styles';

import WarningIcon from '@material-ui/icons/Warning';

import { ButtonCancel } from 'components/common/buttons/ButtonCancel';
import { ButtonConfirm } from 'components/common/buttons/ButtonConfirm';
import { CustomDialogBodySizeEditable, ModalSizeEditableContext } from 'components/common/ModalSizeEditableContext';
import { ContaAReceberActionsContext } from './ContaAReceberActionsContext';

const ModalCircularLoading = () => (
	<CustomDialogBodySizeEditable
		form={{
			component: (
				<Box display='flex' justifyContent='center'>
					<CircularProgress />
				</Box>
			),
		}}
		customActions={<></>}
	/>
);

export const ModalConfirmarDesprovisionamentoMolde = ({ TextComponent, CustomActions }) => {
	const { loading } = useContext(ContaAReceberActionsContext);

	if (loading) return <ModalCircularLoading />;

	return (
		<CustomDialogBodySizeEditable
			title='Tem certeza que deseja desprovisionar esse lançamento de conta a receber?'
			text={TextComponent}
			closeInSubmit={false}
			customActions={CustomActions}
		/>
	);
};

export const ModalConfirmarDesprovisionamentoPadrao = () => {
	const { desprovisionar } = useContext(ContaAReceberActionsContext);
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const {
		palette: { syndikosRed },
	} = useTheme();
	const closesModal = () => {
		setModalValue((v) => ({ ...v, open: false }));
	};

	return (
		<ModalConfirmarDesprovisionamentoMolde
			TextComponent={
				<Box display='flex' alignItems='center' gridGap='20px'>
					<Box>
						<WarningIcon style={{ color: syndikosRed.main }} fontSize='large' />
					</Box>
					<Box>
						<Typography style={{ fontWeight: 'bold', color: syndikosRed.main }}>
							Essa ação é irreversível,
							<br />e irá remover o nosso número e o boleto desta contas a receber
						</Typography>
					</Box>
				</Box>
			}
			CustomActions={
				<>
					<ButtonCancel onClick={closesModal} />
					<ButtonConfirm
						size='small'
						onClick={() => {
							desprovisionar({ callbackSuccess: closesModal });
						}}
						variant='text'
					>
						Confirmar
					</ButtonConfirm>
				</>
			}
		/>
	);
};

export const ModalConfirmarDesprovisionamentoAutomatico = () => {
	const { desprovisionar } = useContext(ContaAReceberActionsContext);
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const {
		palette: { syndikosRed },
	} = useTheme();
	const [senha, setSenha] = React.useState('');
	const closesModal = () => {
		setModalValue((v) => ({ ...v, open: false }));
	};

	return (
		<ModalConfirmarDesprovisionamentoMolde
			TextComponent={
				<Box display='flex' alignItems='center' gridGap='20px'>
					<Box>
						<WarningIcon style={{ color: syndikosRed.main }} fontSize='large' />
					</Box>
					<Box display='grid'>
						<Typography
							style={{
								fontWeight: 'bold',
								color: syndikosRed.main,
							}}
						>
							Essa ação é irreversível,
							<br />e irá remover o nosso número e o boleto desta conta a receber, e a cobrança no banco
							será cancelada!
						</Typography>
						<Typography
							style={{
								fontWeight: 'bold',
								color: syndikosRed.main,
							}}
						>
							Por segurança, solicitamos sua senha de login novamente para cancelar a cobrança.
						</Typography>
						<TextField
							name='password'
							fullWidth
							type='password'
							label='Senha do usuário'
							value={senha}
							variant='outlined'
							onChange={(e) => setSenha(e.target.value)}
							size='small'
						/>
					</Box>
				</Box>
			}
			CustomActions={
				<>
					<ButtonCancel onClick={closesModal} />
					<ButtonConfirm
						size='small'
						onClick={() => {
							desprovisionar({ senha, callbackSuccess: closesModal });
						}}
						variant='text'
						disabled={!senha}
					>
						Confirmar
					</ButtonConfirm>
				</>
			}
		/>
	);
};

const optionsMotivo = [
	{ abv: 'ACT', name: 'Cancelado por acertos' },
	{ abv: 'APC', name: 'Cancelado a pedido do cliente' },
	{ abv: 'PDC', name: 'Cancelado por ter sido pago direto ao cliente' },
	{ abv: 'SUB', name: 'Cancelado por substituição' },
];

export const ModalConfirmarDesprovisionamentoBancoInterAutomatico = () => {
	const { desprovisionar } = useContext(ContaAReceberActionsContext);
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const {
		palette: { syndikosRed },
	} = useTheme();
	const [senha, setSenha] = React.useState('');
	const closesModal = () => {
		setModalValue((v) => ({ ...v, open: false }));
	};

	const [motivo, setMotivo] = React.useState(null);

	return (
		<ModalConfirmarDesprovisionamentoMolde
			TextComponent={
				<Box display='flex' alignItems='center' gridGap='20px'>
					<Box>
						<WarningIcon style={{ color: syndikosRed.main }} fontSize='large' />
					</Box>
					<Box display='grid'>
						<Typography
							style={{
								fontWeight: 'bold',
								color: syndikosRed.main,
							}}
						>
							Essa ação é irreversível,
							<br />e irá remover o nosso número e o boleto desta conta a receber, e a cobrança no banco
							será cancelada!
						</Typography>

						<Typography
							style={{
								fontWeight: 'bold',
								color: syndikosRed.main,
							}}
						>
							Por segurança, solicitamos sua senha de login novamente para cancelar a cobrança.
						</Typography>

						<Box>
							<TextField
								autoComplete='off'
								name='password'
								fullWidth
								type='password'
								label='Senha do usuário'
								value={senha}
								variant='outlined'
								onChange={(e) => setSenha(e.target.value)}
								size='small'
							/>
						</Box>

						<Typography
							style={{
								fontWeight: 'bold',
								color: syndikosRed.main,
							}}
						>
							Informe o motivo do cancelamento.
						</Typography>

						<Box>
							<Autocomplete
								fullWidth
								autoHighlight
								id=''
								value={motivo}
								onChange={(event, newValue) => {
									setMotivo(newValue);
								}}
								options={optionsMotivo}
								getOptionLabel={(option) => option.name}
								renderInput={(params) => (
									<TextField
										autoComplete='off'
										{...params}
										label='Motivo do Cancelamento'
										variant='outlined'
										id=''
									/>
								)}
							/>
						</Box>
					</Box>
				</Box>
			}
			CustomActions={
				<>
					<ButtonCancel onClick={closesModal} />
					<ButtonConfirm
						size='small'
						onClick={() => {
							desprovisionar({ senha, motivo, callbackSuccess: closesModal });
						}}
						variant='text'
						disabled={!senha || !motivo}
					>
						Confirmar
					</ButtonConfirm>
				</>
			}
		/>
	);
};

export const ModalConfirmarDesprovisionamentoBancoDoBrasilAutomatico = () => {
	const { desprovisionar } = useContext(ContaAReceberActionsContext);
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const {
		palette: { syndikosRed },
	} = useTheme();
	const [senha, setSenha] = React.useState('');
	const closesModal = () => {
		setModalValue((v) => ({ ...v, open: false }));
	};

	return (
		<ModalConfirmarDesprovisionamentoMolde
			TextComponent={
				<Box display='flex' alignItems='center' gridGap='20px'>
					<Box>
						<WarningIcon style={{ color: syndikosRed.main }} fontSize='large' />
					</Box>
					<Box display='grid'>
						<Typography
							style={{
								fontWeight: 'bold',
								color: syndikosRed.main,
							}}
						>
							O Banco do Brasil solicita um período de 30 minutos da geração do boletos para executar a
							baixa do mesmo!
						</Typography>
						<Typography
							style={{
								fontWeight: 'bold',
								color: syndikosRed.main,
							}}
						>
							Essa ação é irreversível,
							<br />e irá remover o nosso número e o boleto desta conta a receber, e a cobrança no banco
							será cancelada!
						</Typography>
						<Typography
							style={{
								fontWeight: 'bold',
								color: syndikosRed.main,
							}}
						>
							Por segurança, solicitamos sua senha de login novamente para cancelar a cobrança.
						</Typography>
						<TextField
							name='password'
							fullWidth
							type='password'
							label='Senha do usuário'
							value={senha}
							variant='outlined'
							onChange={(e) => setSenha(e.target.value)}
							size='small'
						/>
					</Box>
				</Box>
			}
			CustomActions={
				<>
					<ButtonCancel onClick={closesModal} />
					<ButtonConfirm
						size='small'
						onClick={() => {
							desprovisionar({ senha, callbackSuccess: closesModal });
						}}
						variant='text'
						disabled={!senha}
					>
						Confirmar
					</ButtonConfirm>
				</>
			}
		/>
	);
};

export const modaisConfirmarDesprovisionamentoPorInstituicaoTipoIntegracao = {
	[undefined]: ModalConfirmarDesprovisionamentoPadrao,
	[null]: ModalConfirmarDesprovisionamentoPadrao,
	['364-0']: ModalConfirmarDesprovisionamentoAutomatico,
	['077-0']: ModalConfirmarDesprovisionamentoBancoInterAutomatico,
	['748-0']: ModalConfirmarDesprovisionamentoAutomatico,
	['756-0']: ModalConfirmarDesprovisionamentoAutomatico,
	['133-0']: ModalConfirmarDesprovisionamentoAutomatico,
	['450-0']: ModalConfirmarDesprovisionamentoAutomatico,
	['001-0']: ModalConfirmarDesprovisionamentoBancoDoBrasilAutomatico,
	['403-0']: ModalConfirmarDesprovisionamentoAutomatico,
};
