import React from 'react';

import { Box } from '@material-ui/core';
import CadastrarDadosIntegracaoAPIField from '../../../../../commons/CadastrarDadosIntegracaoAPIField';
import { ContaCorrenteField } from 'components/resources/condominios/ModalEditaContaBancaria/tabs/TabConta';

const TabConta = () => (
	<Box>
		<Box>
			<Box width='24.5rem'>
				<ContaCorrenteField />
			</Box>
			<Box gridColumnGap='0.5rem' display='flex' alignSelf='baseline' marginTop='6px' marginBottom='4px'>
				<CadastrarDadosIntegracaoAPIField />
			</Box>
		</Box>
	</Box>
);

export default TabConta;
