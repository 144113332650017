import {
	validacoesFieldsTabInformacao,
	createValidateLinhas,
	validatesFieldsRequeridosQuandoEmitindoBoletos,
	validateTipoIntegracao,
	validateCarteira,
	validateEspecieTitulo,
	validateMoeda,
	validateEmissaoBoleto,
	validateDistribuicaoBoleto,
	validateNossoNumero,
	validateSequencialArquivo,
	validateLocalPagamento,
	validateAgencia,
} from '../../../utils/validadoes';

import {
	getValidateQuandoEmitindoBoletos,
	getValidacoesMulta,
	getValidacoesJuros,
	getValidacoesDesconto,
} from '../../default/ContaCorrenteProps';

const validacoesMulta = getValidacoesMulta({ minValue: 1 });

const validacoesJuros = getValidacoesJuros({ minValue: 0 });

const validacoesDesconto = getValidacoesDesconto();

const validacoesInstrucoesCobrancaRemessa = [
	validacoesMulta.tipo,
	validacoesMulta.valor,
	validacoesMulta.dias,

	validacoesJuros.tipo,
	validacoesJuros.valor,
	validacoesJuros.dias,

	validacoesDesconto.tipo,
	validacoesDesconto.valor,
	validacoesDesconto.dias,
];

const listaValidacoesPadroesQuandoEmitindoBoletos = [
	...validatesFieldsRequeridosQuandoEmitindoBoletos,
	validateTipoIntegracao,
	validateCarteira,
	validateEspecieTitulo,
	validateMoeda,
	validateEmissaoBoleto,
	validateDistribuicaoBoleto,
	validateNossoNumero,
	validateSequencialArquivo,
	validateLocalPagamento,
	createValidateLinhas({ numeroLinhas: 4, maxValue: 80 }),
];

const validateQuandoEmitindoBoletos = getValidateQuandoEmitindoBoletos([
	...validacoesInstrucoesCobrancaRemessa,
	...listaValidacoesPadroesQuandoEmitindoBoletos,
]);

const validacoes = [...validacoesFieldsTabInformacao, validateAgencia, validateQuandoEmitindoBoletos];

export default validacoes;
