import React from 'react';

import {
	formatCedentePattern1,
	getDadosFormattingContaCorrente,
	parseCedentePattern1,
} from '../../../utils/formaters_parses';

import PadraoTabInstrucoesCobrancaRemessaBase, {
	MultaFields,
	JurosFields,
	DescontoFields,
} from '../../../tabs/TabInstrucoesCobrancaRemessa';

import { getFormatarOptions } from '../../../utils/functions';

import DefaultContaCorrenteProps, { defaultComponentesProps } from '../../default/ContaCorrenteProps';
import formatarInitialValues from '../../default/ContaCorrenteProps/formatarInitialValues';

import formatadoresPayloadValues from './formatadoresPayloadValues';
import TabInstrucoesCobrancaBoleto from './TabInstrucoesCobrancaBoleto';
import validacoes from './validacoes';

const TabInstrucoesCobrancaRemessa = (props) => (
	<PadraoTabInstrucoesCobrancaRemessaBase
		{...props}
		ColunaDireitaComponentes={[<MultaFields />, <JurosFields />, <DescontoFields />]}
		ColunaEsquerdaComponentes={[]}
	/>
);

const ContaCorrenteProps = {
	...DefaultContaCorrenteProps,
	instituicaoCodigo: '084',
	props: {
		...defaultComponentesProps,
		conta_corrente: {
			...getDadosFormattingContaCorrente(7, 1),
		},
		conta_cedente: {
			parse: parseCedentePattern1(20),
			format: formatCedentePattern1(20),
		},
	},
	getOptions: getFormatarOptions('084', { agencia: 'agencias_uniprime' }),
	validacoes: validacoes,
	formatadoresPayloadValues: formatadoresPayloadValues,
	tabContents: {
		...DefaultContaCorrenteProps.tabContents,
		TabInstrucoesCobrancaRemessa,
		TabInstrucoesCobrancaBoleto,
	},
	formatarInitialValues,
};

export default ContaCorrenteProps;
