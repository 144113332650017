import React from 'react';

import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import { makeStyles } from '@material-ui/core/styles';

import { ModalSizeEditableContext } from 'components/common/ModalSizeEditableContext';
import { ArrecadacoesContext } from '../../../../ArrecadacoesContext';
import { RemessasContext } from '../../contextos';
import { ModalConfiguracaoImpressao } from '../../modais';

import useDadosPorInstituicaoTipoIntegracaoSicrediAutomatico from './DadosPorInstituicao/DadosIntegracaoSicrediAutomatico';
import useDadosPorInstituicaoTipoIntegracaoInterAutomatico from './DadosPorInstituicao/DadosIntegracaoInterAutomatico';
import useDadosPorInstituicaoTipoIntegracaoSicoobAutomatico from './DadosPorInstituicao/DadosIntegracaoSicoobAutomatico';
import useDadosPorInstituicaoTipoIntegracaoGerencianetAutomatico from './DadosPorInstituicao/DadosIntegracaoGerencianetAutomatico';
import useDadosPorInstituicaoTipoIntegracaoCresolAutomatico from './DadosPorInstituicao/DadosIntegracaoCresolAutomatico';
import useDadosPorInstituicaoTipoIntegracaoCondoContaAutomatico from './DadosPorInstituicao/DadosIntegracaoCondoContaAutomatico';
import useDadosPorInstituicaoTipoIntegracaoBancoDoBrasilAutomatico from './DadosPorInstituicao/DadosIntegracaoBancoDoBrasilAutomatico';
import useDadosPorInstituicaoTipoIntegracaoContBankAutomatico from './DadosPorInstituicao/DadosIntegracaoContBankAutomatico';
import useDadosPorInstituicaoTipoIntegracaoBancoCoraAutomatico from './DadosPorInstituicao/DadosIntegracaoBancoCoraAutomatico';

const useStyles = makeStyles((theme) => ({
	warningColor: {
		color: theme.palette.syndikosRed.main,
	},
	baixaBoletosButton: {
		color: theme.palette.syndikosRed.contrastText,
		backgroundColor: theme.palette.syndikosRed.main,
		'&:hover': {
			backgroundColor: theme.palette.syndikosRed.dark,
		},
	},
}));

const ButtonConfigurarRemessasBoletosLabelPadrao = () => {
	const { contaBDestinoIsValid } = React.useContext(ArrecadacoesContext);

	return contaBDestinoIsValid ? 'Gerar Remessas e Boletos' : 'Conta bancária inválida para gerar remessas e boletos';
};

const useDadosPorInstituicaoTipoIntegracaoPadrao = () => {
	const { setModalValue } = React.useContext(ModalSizeEditableContext);

	const onClick = React.useCallback(() => {
		setModalValue((v) => ({
			...v,
			open: true,
			dialogBody: <ModalConfiguracaoImpressao />,
		}));
	}, [setModalValue]);

	return React.useMemo(
		() => ({
			Label: ButtonConfigurarRemessasBoletosLabelPadrao,
			onClick,
		}),
		[onClick]
	);
};

const hooksUseDadosPorInstituicaoTipoIntegracao = {
	['364-0']: useDadosPorInstituicaoTipoIntegracaoGerencianetAutomatico,
	['077-0']: useDadosPorInstituicaoTipoIntegracaoInterAutomatico,
	['748-0']: useDadosPorInstituicaoTipoIntegracaoSicrediAutomatico,
	['756-0']: useDadosPorInstituicaoTipoIntegracaoSicoobAutomatico,
	['133-0']: useDadosPorInstituicaoTipoIntegracaoCresolAutomatico,
	['450-0']: useDadosPorInstituicaoTipoIntegracaoCondoContaAutomatico,
	['001-0']: useDadosPorInstituicaoTipoIntegracaoBancoDoBrasilAutomatico,
	['332-0']: useDadosPorInstituicaoTipoIntegracaoContBankAutomatico,
	['403-0']: useDadosPorInstituicaoTipoIntegracaoBancoCoraAutomatico,
};

const useGetHookDadosPorInstituicaoTipoIntegracao = () => {
	const { identificacaoInstituicaoTipoIntegracao } = React.useContext(ArrecadacoesContext);

	const useDadosPorInstituicaoTipoIntegracao = React.useMemo(
		() =>
			hooksUseDadosPorInstituicaoTipoIntegracao[identificacaoInstituicaoTipoIntegracao] ||
			useDadosPorInstituicaoTipoIntegracaoPadrao,
		[identificacaoInstituicaoTipoIntegracao]
	);

	return useDadosPorInstituicaoTipoIntegracao;
};

export const ButtonConfigurarRemessasBoletos = () => {
	const classes = useStyles();
	const { contaBDestinoIsValid, contaBDestinoId } = React.useContext(ArrecadacoesContext);
	const { idsRegistrosSelecionados, registrosSelecionados } = React.useContext(RemessasContext);
	const useDadosPorInstituicaoTipoIntegracao = useGetHookDadosPorInstituicaoTipoIntegracao();
	const [loadingRequest, setLoadingRequest] = React.useState(false);
	const [valid, setValid] = React.useState(true);

	React.useEffect(() => {
		const registrosArray = Object.values(registrosSelecionados);
		const temDataValida = registrosArray.every((registro) => {
			const dataVencimento = new Date(registro.data_vencimento + 'T00:00:00');
			const hoje = new Date();
			hoje.setHours(0, 0, 0, 0);
			const dataValida = dataVencimento >= hoje;
			return dataValida;
		});
		setValid(temDataValida);
	}, [registrosSelecionados]);

	const { Label, onClick } = useDadosPorInstituicaoTipoIntegracao({ setLoadingRequest, setValid });

	return (
		<Button
			size='small'
			disabled={
				!contaBDestinoId ||
				!contaBDestinoIsValid ||
				!idsRegistrosSelecionados.length ||
				!valid ||
				loadingRequest
			}
			variant='contained'
			startIcon={<GetAppIcon />}
			className={classes.baixaBoletosButton}
			onClick={onClick}
		>
			<Label />
		</Button>
	);
};

export default ButtonConfigurarRemessasBoletos;
