import React from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import { Button } from '@material-ui/core';

import { format, parseISO } from 'date-fns';

import { formatFracoesThenGetInterval, DIAS_DA_SEMANA, TIPO_RESERVA } from './utils';

import { ConsultaDeReservasContext } from '../../context/AgendamentoContext';
import { ConfirmDialog } from '../ModalConfirm/ConfirmDialog';
import { LoadingButton } from '../../buttons/LoadingButton';
import { StyledCard } from './components/StyledCard';

import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import PaymentIcon from '@material-ui/icons/Payment';
import HomeIcon from '@material-ui/icons/Home';
import EditIcon from '@material-ui/icons/Edit';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	deleteButton: {
		backgroundColor: theme.palette.syndikosRed.main,
		color: '#fff',
		'&:hover': {
			backgroundColor: theme.palette.syndikosRed.main,
			color: '#fff',
		},
	},
	editarButton: {
		backgroundColor: theme.secondary[200],
		color: '#fff',
		'&:hover': {
			backgroundColor: theme.secondary[200],
			color: '#fff',
		},
	},
	showMoreButton: {
		backgroundColor: theme.secondary[200],
		color: '#fff',
		'&:hover': {
			backgroundColor: theme.secondary[200],
			color: '#fff',
		},
		height: '1.3rem',
		width: '6rem',
		'font-size': 'x-small',
	},
	titleCard: {
		backgroundColor: theme.secondary[200],
		color: '#fff',
		padding: '0.5rem',
		'border-radius': '4px',
		display: 'flex',
		'justify-content': 'center',
	},
	observacoesContainer: {
		overflowWrap: 'break-word',
		whiteSpace: 'pre-wrap',
		wordBreak: 'break-word',
		maxWidth: '100%',
		marginLeft: '5px',
	},
	observacoesWrapper: {
		display: 'flex',
		alignItems: 'flex-start',
	},
}));

const isOnPast = (date) => {
	const today = new Date();
	today.setHours(0, 0, 0, 0);
	return date < today;
};

export const CardReserva = ({ data }) => {
	const { actionCallBackReloadReserva, setDadoCardAndShowModalEdicao } = React.useContext(ConsultaDeReservasContext);

	const [loadingCancelamento, setLoadingCancelamento] = React.useState(false);
	const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);
	const [showAllHorarios, setShowAllHorarios] = React.useState(false);

	const dp = useDataProvider();
	const classes = useStyles();
	const notify = useNotify();

	const horariosDaReserva = formatFracoesThenGetInterval[data.fracao](data.reservas_intervalos_tempo);
	const diaReservado = format(parseISO(data.dia_reservado), 'dd/MM/yyyy');
	const custoAmbiente = data.taxa_por_uso
		? parseFloat(data.valor_cobrado).toLocaleString('pt-br', {
				style: 'currency',
				currency: 'BRL',
		  })
		: 'Isento';

	const formatarTaxaCobranca = () => {
		if (!data.taxa_por_uso) {
			return 'Isento';
		} else if (data?.modulo_cobranca === 'COBRANCA_POR_RESERVA') {
			return parseFloat(data?.valor_taxa).toLocaleString('pt-br', {
				style: 'currency',
				currency: 'BRL',
			});
		} else if (data?.modulo_cobranca === 'COBRANCA_POR_PERIODO_HORA') {
			return `${parseFloat(data?.valor_taxa).toLocaleString('pt-br', {
				style: 'currency',
				currency: 'BRL',
			})} por período/hora`;
		}
		return 'Erro ao exibir valor';
	};

	const disabledButton = isOnPast(parseISO(data.dia_reservado));

	const cancelarReserva = React.useCallback(() => {
		setLoadingCancelamento(true);
		dp.delete('reserva_agendada_syndkos', { id: data.id })
			.then(() => {
				notify(`Reserva do dia ${diaReservado} cancelada com sucesso!`);
				actionCallBackReloadReserva();
			})
			.catch((error) => {
				const data = error?.response?.data || {};
				if ('arrecadacao_reserva_provisionada' in data) {
					return notify(
						'A taxa de cobrança desta reserva já foi provisionada, e por isso não pode ser cancelada.',
						'warning'
					);
				}

				if ('cancelamento_horas_antes' in data) {
					return notify('Prazo para cancelamento esgotado.', 'warning');
				}

				return notify('Erro inesperado.', 'warning');
			})
			.finally(() => setLoadingCancelamento(false));
	}, [data.id, dp, notify, diaReservado, setLoadingCancelamento, actionCallBackReloadReserva]);

	return (
		<StyledCard>
			<div className={classes.titleCard}>
				<span role='img' aria-label='Reserva'>
					<strong>Reserva</strong>
				</span>
			</div>
			<div>
				<span role='img' aria-label='Data da reserva'>
					<CalendarTodayIcon /> Data da reserva:
					<strong>
						{diaReservado}, {DIAS_DA_SEMANA[data.dia_semana]}
					</strong>
				</span>
			</div>
			<div>
				<span role='img' aria-label='Unidade'>
					<HomeIcon /> Unidade:
					<strong>
						{data.unidade_dados.nome_unidade}
						{data.unidade_dados.grupo_nome ? `, ${data.unidade_dados.grupo_nome}` : null}
					</strong>
				</span>
			</div>

			<div>
				<span role='img' aria-label='Tipo da reserva'>
					<LocalOfferIcon /> Tipo da reserva:
					<strong>{TIPO_RESERVA[data.fracao]}</strong>
				</span>
			</div>
			<div>
				<span role='img' aria-label='Taxa de cobrança'>
					<ReceiptIcon />
					Taxa de cobrança:
					<strong>{formatarTaxaCobranca()}</strong>
				</span>
			</div>
			<div>
				<span role='img' aria-label='Custo da reserva'>
					<PaymentIcon /> Custo da reserva:
					<strong>{custoAmbiente}</strong>
				</span>
			</div>
			<div>
				<span role='img' aria-label='Horarios Reservados'>
					<AccessTimeIcon /> Horários Reservados:
					{horariosDaReserva.length > 1 && (
						<div>
							<Button
								variant='contained'
								className={classes.showMoreButton}
								onClick={() => setShowAllHorarios((prev) => !prev)}
							>
								{showAllHorarios ? 'VER MENOS' : 'VER TODOS'}
							</Button>
						</div>
					)}
				</span>
				<ul>
					{showAllHorarios
						? horariosDaReserva.map((horario, index) => (
								<li key={index}>
									<strong>{horario}</strong>
								</li>
						  ))
						: horariosDaReserva.slice(0, 1).map((horario, index) => (
								<li key={index}>
									<strong>{horario}</strong>
								</li>
						  ))}
				</ul>
			</div>
			<div>
				<div className={classes.observacoesWrapper}>
					<EditIcon />
					<span style={{ marginLeft: 10, marginTop: 3 }}>
						Observações: <strong className={classes.observacoesContainer}>{data.observacoes}</strong>
					</span>
				</div>
			</div>
			<div
				style={{
					display: 'flex',
					gap: '10px',
					justifyContent: 'flex-end',
				}}
			>
				<LoadingButton
					variant='contained'
					className={classes.deleteButton}
					onClick={() => setShowConfirmDialog(true)}
					loading={loadingCancelamento}
					disabled={disabledButton}
				>
					Cancelar Reserva
				</LoadingButton>
				<ConfirmDialog
					classes={classes}
					onConfirm={cancelarReserva}
					showConfirmDialog={showConfirmDialog}
					setShowConfirmDialog={setShowConfirmDialog}
					msgText={'Essa ação irá cancelar esta reserva.'}
					actionText={'Cancelar'}
				/>
				<LoadingButton
					variant='contained'
					className={classes.editarButton}
					onClick={() => setDadoCardAndShowModalEdicao(data)}
					loading={loadingCancelamento}
					disabled={disabledButton}
				>
					Editar
				</LoadingButton>
			</div>
		</StyledCard>
	);
};
