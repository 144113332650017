import React from 'react';

import { Box, FormControlLabel, FormGroup, Checkbox } from '@material-ui/core';

import { BoletosContext } from '../abas/ContasReceberBoletos/BoletosContextProvider';
import PeriodoSelect from './SelecionarPeriodo/PeriodoSelect';

const TIPO_IMPRESSAO_DEVEDORES_MAP = {
	'0': false,
	'1': true,
} as any;

const BoxConfigPrestacaoDeContas = () => {
	const {
		opcoesImpressao: {
			imprimir_contas_analiticas,
			imprimir_resumo_contas_bancarias,
			imprimir_posicao_prestacao_contas_direita,
			tipo_impressao_devedores_pc,
			separar_receitas_por_referencia,
		},
		handleChangeOpcoesImpressao,
	} = React.useContext(BoletosContext);

	return (
		<Box style={{ marginTop: '0.5em' }}>
			<PeriodoSelect label='Data da Prestação de Contas' />
			<br />
			<FormGroup>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimir_contas_analiticas}
							onChange={(e, checked) =>
								handleChangeOpcoesImpressao(checked, 'imprimir_contas_analiticas')
							}
							name='imprimir_contas_analiticas'
						/>
					}
					label='Imprimir contas analíticas'
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimir_resumo_contas_bancarias}
							onChange={(e, checked) =>
								handleChangeOpcoesImpressao(checked, 'imprimir_resumo_contas_bancarias')
							}
							name='imprimir_resumo_contas_bancarias'
						/>
					}
					label='Imprimir resumo das contas bancárias'
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimir_posicao_prestacao_contas_direita}
							onChange={(e, checked) =>
								handleChangeOpcoesImpressao(checked, 'imprimir_posicao_prestacao_contas_direita')
							}
							name='imprimir_posicao_prestacao_contas_direita'
						/>
					}
					label='Imprimir a Prestação de Contas à direita'
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={TIPO_IMPRESSAO_DEVEDORES_MAP[tipo_impressao_devedores_pc]}
							onChange={(e, checked) =>
								handleChangeOpcoesImpressao(checked ? '1' : '0', 'tipo_impressao_devedores_pc')
							}
						/>
					}
					label='Imprimir totais de inadimplentes'
				/>

				<FormControlLabel
					control={
						<Checkbox
							checked={separar_receitas_por_referencia}
							onChange={(e, checked) => {
								handleChangeOpcoesImpressao(checked, 'separar_receitas_por_referencia');
								if (checked) {
									handleChangeOpcoesImpressao(true, 'imprimir_contas_analiticas');
								}
							}}
							name='separar_receitas_por_referencia'
						/>
					}
					label='Separar receitas por referência'
				/>
			</FormGroup>
		</Box>
	);
};

export default BoxConfigPrestacaoDeContas;
