import React from 'react';
import { ArrecadacoesContext } from '../ArrecadacoesContext';
import TabListComponentArrecadacao from './TabListComponentArrecadacao';

const labelPadrao = (
	<span>
		Gerar
		<br />
		Remessa
	</span>
);

const labelAPI = (
	<span>
		Gerar
		<br />
		Boletos
	</span>
);

const labelsPorInstituicaoEIntegracao = {
	[undefined]: labelPadrao,
	['364-0']: labelAPI,
	['077-0']: labelAPI,
	['748-0']: labelAPI,
	['756-0']: labelAPI,
	['133-0']: labelAPI,
	['450-0']: labelAPI,
	['001-0']: labelAPI,
	['332-0']: labelAPI,
	['403-0']: labelAPI,
};

export const TabListComponentArrecadacaoGerarRemessaEBoletos = (props) => {
	const { identificacaoInstituicaoTipoIntegracao } = React.useContext(ArrecadacoesContext);
	return (
		<TabListComponentArrecadacao
			{...props}
			label={labelsPorInstituicaoEIntegracao[identificacaoInstituicaoTipoIntegracao] || labelPadrao}
		/>
	);
};

export default TabListComponentArrecadacaoGerarRemessaEBoletos;
