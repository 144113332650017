import { defaultFormatarValuesAgencia, defaultFormatarValuesContaCorrente } from '../../../utils/functions';
import {
	createformataPayloadValuesLinhas,
	formataPayloadValuesBeneficiario,
	formataPayloadValuesDesconto,
	formataPayloadValuesIdContaBancaria,
	formataPayloadValuesInstrucoesComTipoValor,
	formataPayloadValuesTabEmissaoBoletos,
	formataPayloadValuesTabInformacao,
	getFormatadorPayloadCobrancaConfig,
	getPadraoPayloadValuesInstrucoesRemessa,
} from '../../default/ContaCorrenteProps/getFormataPayloadValues';

const formataPayloadValuesTabContaCobrancaConfig = ({ values: { conta_cedente } }: any) => ({
	conta_cedente,
});

const formataPayloadValuesMulta = (props: any) =>
	formataPayloadValuesInstrucoesComTipoValor({
		...props,
		field: 'multa',
		valorIsensao: 'ISE',
	});

const formataPayloadValuesJuros = (props: any) =>
	formataPayloadValuesInstrucoesComTipoValor({
		...props,
		field: 'juros',
		valorIsensao: 'JIS',
	});

const formataPayloadValuesTabInstrucoesCobrancaRemessa = ({ values }: any) => {
	const payload = {
		...getPadraoPayloadValuesInstrucoesRemessa(),
		...formataPayloadValuesMulta({ values }),
		...formataPayloadValuesJuros({ values }),
		...formataPayloadValuesDesconto({ values }),
	};
	if (!payload.desconto_dias) payload.desconto_dias = undefined;
	return payload;
};

const formatadoresPayloadValues = {
	// Dados de Tab Informação
	tab_informacao: formataPayloadValuesTabInformacao,

	// Dados de Tab Emissão Boletos
	tab_emissao_boletos: formataPayloadValuesTabEmissaoBoletos,

	//Dados de TabConta

	agencia: defaultFormatarValuesAgencia,
	conta_corrente: defaultFormatarValuesContaCorrente,

	cobranca_config: getFormatadorPayloadCobrancaConfig({
		formatadores: [
			// Dados Fixos
			formataPayloadValuesIdContaBancaria,

			// Dados TabConta
			formataPayloadValuesTabContaCobrancaConfig,

			// Dados de Tab Emissão Boletos
			formataPayloadValuesBeneficiario,

			// Dados de Tab Instruções de Cobrança Remessa
			formataPayloadValuesTabInstrucoesCobrancaRemessa,

			// Dados de Tab Instruções de Cobrança Boleto
			createformataPayloadValuesLinhas({ numeroLinhas: 5 }),
		],
	}),
};

export default formatadoresPayloadValues;
