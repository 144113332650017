import React, { useRef } from 'react';
import { useInput } from 'ra-core';
import { Field } from 'react-final-form';
import { Avatar, Box, Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Base64 from '../../../../utils/Base64';
import './FileInput.css';
import { useTheme } from '@material-ui/core/styles';

import { validateDimensions, validateDimensionsMarcaDAgua } from '../../../../fieldControl/imageUtils';
import { BotaoRemover } from 'components/common/buttons/BotaoRemover';

const ImageFormInput = ({ source, label, width = null, height = null, ...params }) => {
	const inputRef = useRef(null);
	const theme = useTheme();

	const {
		id,
		input: { value, onChange },
		meta,
	} = useInput({ source, type: 'file', ...params });

	const handleFileInput = async ({ target: { files } }) => {
		if (files?.length) {
			const base64File = await Base64.encode(files[0]);
			return onChange(base64File);
		}
		return onChange(null);
	};

	const handleRemoveFileInput = () => {
		if (value) {
			inputRef.current.value = '';
			return onChange(null);
		}
	};

	const TooltipButtonRemoverLogo = () => (
		<Tooltip title={'Remover Logo'}>
			<span>
				<BotaoRemover id='botao-remover-logo-escritorio' disabled={!value} onClick={handleRemoveFileInput} />
			</span>
		</Tooltip>
	);
	const TooltipButtonRemoverMarcaDAgua = () => (
		<Tooltip title={'Remover Marca DÁgua'}>
			<span>
				<BotaoRemover
					id='botao-remover-marca-d-agua-escritorio'
					disabled={!value}
					onClick={handleRemoveFileInput}
				/>
			</span>
		</Tooltip>
	);

	return (
		<Box
			style={{
				display: 'flex',
				'flex-direction': 'column',
				'align-items': 'center',
			}}
		>
			<Field
				id={id}
				source={source}
				name={source}
				validate={(value) => {
					if (value) {
						return source === 'logo'
							? validateDimensions(1200, 1200, value)
							: validateDimensionsMarcaDAgua(1200, 1200, value);
					}
					return undefined;
				}}
			>
				{({ input }) => {
					return (
						<div className='fileinput-container'>
							<div onClick={() => inputRef.current.click()}>
								<label htmlFor='contained-button-file'>
									<IconButton>
										<Avatar
											variant='square'
											src={value}
											style={{
												margin: '8px 10px',
												width: '134px',
												height: '95px',
											}}
										/>
									</IconButton>
								</label>
							</div>
							<div className='label-fileInput'>
								<label>{label}</label>
							</div>
							<input style={{ display: 'none' }} {...input} />
							<input
								style={{ display: 'none' }}
								accept='image/png, image/jpeg, image/jpg'
								ref={inputRef}
								disabled={!!value}
								onChange={handleFileInput}
								type='file'
							/>
							{value && meta.error && (
								<span style={{ color: theme.palette.syndikosRed.main }}>{meta.error}</span>
							)}
						</div>
					);
				}}
			</Field>
			{id == 'logo' ? <TooltipButtonRemoverLogo /> : <TooltipButtonRemoverMarcaDAgua />}
		</Box>
	);
};

export default ImageFormInput;
