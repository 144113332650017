import React from 'react';

import InstrucoesRemessaFields from '../../../../../commons/InstrucoesRemessaFields';

type ValoresProps = {
	sources: {
		sourceValor?: any;
	};
	values: any;
	optionsIsencoesTipo: any;
	pristine?: any;
};

const emptyProps = {};

const MultaFields = ({ propsTipo = emptyProps, propsValor = emptyProps, propsDias = emptyProps }) => (
	<InstrucoesRemessaFields
		sourcePrincipal='multa'
		propsTipo={{
			source: 'multa_tipo',
			label: 'Multa',
			...propsTipo,
		}}
		propsValor={{
			source: 'multa_valor',
			...propsValor,
		}}
		propsDias={{
			source: 'multa_dias',
			...propsDias,
		}}
	/>
);

export const getPropsOnChangeTipoPadraoMulta = ({
	sources: { sourceValor } = emptyProps,
	values,
	optionsIsencoesTipo,
}: ValoresProps) => ({
	values: { [sourceValor]: values[sourceValor] },
	sources: { sourceValor },
	optionsIsencoesTipo,
});

export const createOnChangeTipoPadraoMulta =
	({ sources: { sourceValor } = emptyProps, values, pristine, optionsIsencoesTipo }: ValoresProps, change: any) =>
	(_: any, tipoValue: any, input: any) => {
		input.onChange(tipoValue);

		const valorValue = values[sourceValor];
		if (!tipoValue || optionsIsencoesTipo[tipoValue.id]) {
			sourceValor && change(sourceValor, undefined);
		} else {
			if (tipoValue.id === 'PRC') {
				!valorValue && change(sourceValor, undefined);
			} else {
				!pristine && !valorValue && change(sourceValor, undefined);
			}
		}
	};
export default MultaFields;
