import React from 'react';

import { Controller } from 'react-hook-form';

import { FormControlLabel, Tooltip, Switch } from '@material-ui/core';

import { SimplesSyndikosSelect } from '../../SyndikosSelect';

export const SyndkosSwitchForm = ({
	controllerName,
	control,
	tooltipTitle,
	formControlLabel,
	disabled = false,
	controllerValue,
	...props
}) => (
	<Controller
		name={controllerName}
		control={control}
		render={({ field: { onChange, value } }) => (
			<FormControlLabel
				control={
					<Tooltip title={<>{tooltipTitle}</>}>
						<Switch
							color='primary'
							checked={controllerValue ?? value}
							onChange={onChange}
							disabled={disabled}
						/>
					</Tooltip>
				}
				label={formControlLabel}
				labelPlacement='end'
				style={{ margin: 'auto' }}
			/>
		)}
		{...props}
	/>
);

export const SyndkosSelectForm = ({
	controllerName,
	control,
	selectChoice,
	selectError,
	selectHelperText,
	selectDisabled,
}) => (
	<Controller
		name={controllerName}
		control={control}
		render={({ field: { onChange, value } }) => (
			<SimplesSyndikosSelect
				choices={selectChoice}
				defaultValue={null}
				value={value}
				onChange={onChange}
				error={selectError}
				helpertext={selectHelperText}
				disabled={selectDisabled}
				variant='outlined'
				inputProps={{ style: { maxHeight: '10px' } }}
				fullWidth
			/>
		)}
	/>
);
