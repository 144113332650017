import React, { useContext, useState } from 'react';

import { Collapse, Box, Button, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CircularProgress from '@material-ui/core/CircularProgress';

import { BoletosContext } from './abas/ContasReceberBoletos/BoletosContextProvider';

import ComunicadoTemplate from 'components/common/ComunicadoTemplate';
import BoxConfigDevedores from './ConfiguracoesBoleto/BoxConfigDevedores';
import BoxConfigDemonstrativoArrecadacao from './ConfiguracoesBoleto/BoxConfigDemonstrativoArrecadacao';
import BoxConfigResumoRateioValorTotal from './ConfiguracoesBoleto/BoxConfigResumoRateioValorTotal';
import BoxConfigValorUnidadeSubordinada from './ConfiguracoesBoleto/BoxConfigValorUnidadeSubordinada';
import BoxConfigResumoRateio from './ConfiguracoesBoleto/BoxConfigResumoRateio';
import BoxConfigDemonstrativoMensal from './ConfiguracoesBoleto/BoxConfigDemonstrativoMensal';
import BoxConfigPrestacaoDeContas from './ConfiguracoesBoleto/BoxConfigPrestacaoDeContas';
import BoxConfigMarcaDAgua from './ConfiguracoesBoleto/BoxConfigMarcaDAgua';
import BoxConfigProprietarioEInquilino from './ConfiguracoesBoleto/BoxConfigProprietarioEInquilino';
import BoxConfigDemonstrativoVerso from './ConfiguracoesBoleto/BoxConfigDemonstrativoVerso';

const optionsModeloDemonstrativo = [
	{ id: 'DA', name: 'Demonstrativo de Arrecadação' },
	{ id: 'PC', name: 'Prestação de Contas' },
	{ id: 'DP', name: 'Demonstrativo de Arrecadação e Prestação de Contas' },
	{ id: 'DM', name: 'Demonstrativo Mensal' },
	{ id: 'AM', name: 'Demonstrativo de Arrecadação e Demonstrativo Mensal' },
];

const optionsModeloDemonstrativoVerso = [
	{ id: 'DA', name: 'Demonstrativo de Arrecadação' },
	{ id: 'PC', name: 'Prestação de Contas' },
	{ id: 'DM', name: 'Demonstrativo Mensal' },
];

const BoxModeloDemonstrativo = () => {
	const { modeloDemonstrativo, setModeloDemonstrativo } = useContext(BoletosContext);
	return (
		<Box display='flex' mb='1em' mt='0.5em'>
			<Autocomplete
				value={modeloDemonstrativo || optionsModeloDemonstrativo[0]}
				options={optionsModeloDemonstrativo}
				getOptionLabel={(o) => o.name}
				onChange={(_, newValue) => setModeloDemonstrativo(newValue)}
				renderInput={(params) => (
					<TextField {...params} variant='outlined' label='Modelo de Demonstrativo Impresso ao Boleto' />
				)}
				autoHighlight
				disableClearable
				noOptionsText='Nenhum modelo disponível'
				size='small'
				fullWidth
			/>
		</Box>
	);
};

const BoxModeloDemonstrativoVerso = () => {
	const {
		modeloDemonstrativo,
		modeloDemonstrativoVerso,
		setModeloDemonstrativoVerso,
		opcoesImpressao: { imprimir_verso_relatorio },
	} = useContext(BoletosContext);

	const bloqueios = {
		DP: ['DA', 'PC'],
		AM: ['DA', 'DM'],
	};

	const idsBloqueados = modeloDemonstrativo ? bloqueios[modeloDemonstrativo.id] || [modeloDemonstrativo.id] : [];
	const filteredOptions = optionsModeloDemonstrativoVerso.filter((option) => !idsBloqueados.includes(option.id));

	React.useEffect(() => {
		if (modeloDemonstrativoVerso && idsBloqueados.includes(modeloDemonstrativoVerso.id)) {
			setModeloDemonstrativoVerso(null);
		}
	}, [modeloDemonstrativo, modeloDemonstrativoVerso, setModeloDemonstrativoVerso]);

	return (
		<Box display='flex' minWidth='0' flex='1'>
			<Autocomplete
				value={modeloDemonstrativoVerso}
				options={filteredOptions}
				getOptionLabel={(o) => o.name}
				onChange={(_, newValue) => setModeloDemonstrativoVerso(newValue)}
				renderInput={(params) => (
					<TextField {...params} variant='outlined' label='Modelo de Demonstrativo Verso' />
				)}
				autoHighlight
				disableClearable
				disabled={!imprimir_verso_relatorio}
				noOptionsText='Nenhum modelo disponível'
				size='small'
				fullWidth
			/>
		</Box>
	);
};

const BoxConfigGeracaoBoleto = () => {
	const {
		opcoesImpressao: { imprimir_verso_relatorio },
		modeloDemonstrativo,
		modeloDemonstrativoVerso,
		loading,
	} = useContext(BoletosContext);
	const [expandedArrecadacao, setExpandedArrecadacao] = useState(true);
	const [expandedPresConta, setExpandedPresConta] = useState(true);
	const [expandedComunicado, setExpandedComunicado] = useState(false);
	const [expandedDemonsMensal, setExpandedDemonsMensal] = useState(true);
	const [expandedConfiguracaoBoleto, setExpandedConfiguracaoBoleto] = useState(false);

	if (loading)
		return (
			<Box style={{ display: 'flex', justifyContent: 'center' }}>
				<CircularProgress size={40} />
			</Box>
		);

	const modelosAtivamArrecadacao = ['DA', 'DP', 'AM'];
	const modelosAtivamPrestacaoContas = ['PC', 'DP'];
	const modelosAtivamDemonstrativoMensal = ['DM', 'AM'];

	const deveExibirArrecadacao =
		modelosAtivamArrecadacao.includes(modeloDemonstrativo?.id) ||
		(imprimir_verso_relatorio && modelosAtivamArrecadacao.includes(modeloDemonstrativoVerso?.id));

	const deveExibirPrestacaoContas =
		modelosAtivamPrestacaoContas.includes(modeloDemonstrativo?.id) ||
		(imprimir_verso_relatorio && modelosAtivamPrestacaoContas.includes(modeloDemonstrativoVerso?.id));

	const deveExibirDemonstrativoMensal =
		modelosAtivamDemonstrativoMensal.includes(modeloDemonstrativo?.id) ||
		(imprimir_verso_relatorio && modelosAtivamDemonstrativoMensal.includes(modeloDemonstrativoVerso?.id));

	return (
		<Box>
			<BoxModeloDemonstrativo />
			<Box display='flex' width='100%' mb='1em' mt='0.5em' flexWrap='nowrap'>
				<BoxConfigDemonstrativoVerso />
				<BoxModeloDemonstrativoVerso />
			</Box>

			{deveExibirArrecadacao && (
				<Box style={{ marginTop: '0.5em' }}>
					<Button
						onClick={() => setExpandedArrecadacao(!expandedArrecadacao)}
						variant='contained'
						color='primary'
						fullWidth
						size='medium'
						startIcon={!expandedArrecadacao ? <ChevronRightIcon /> : <ExpandMoreIcon />}
					>
						Demonstrativo de Arrecadação
					</Button>
					<Collapse in={expandedArrecadacao} style={{ margin: '0.5em' }}>
						<BoxConfigDemonstrativoArrecadacao />
						<BoxConfigValorUnidadeSubordinada />
						<BoxConfigResumoRateio />
						<BoxConfigResumoRateioValorTotal />
						<BoxConfigDevedores />
					</Collapse>
				</Box>
			)}

			{deveExibirPrestacaoContas && (
				<Box>
					<Button
						onClick={() => setExpandedPresConta(!expandedPresConta)}
						variant='contained'
						color='primary'
						fullWidth
						size='medium'
						startIcon={!expandedPresConta ? <ChevronRightIcon /> : <ExpandMoreIcon />}
					>
						Prestação de Contas
					</Button>
					<Collapse in={expandedPresConta} style={{ margin: '0.5em' }}>
						<BoxConfigPrestacaoDeContas />
					</Collapse>
				</Box>
			)}

			{deveExibirDemonstrativoMensal && (
				<Box>
					<Button
						onClick={() => setExpandedDemonsMensal(!expandedDemonsMensal)}
						variant='contained'
						color='primary'
						fullWidth
						size='medium'
						startIcon={!expandedDemonsMensal ? <ChevronRightIcon /> : <ExpandMoreIcon />}
					>
						Demonstrativo Mensal
					</Button>
					<Collapse in={expandedDemonsMensal} style={{ margin: '0.5em' }}>
						<BoxConfigDemonstrativoMensal />
					</Collapse>
				</Box>
			)}

			<Box>
				<Button
					onClick={() => setExpandedComunicado(!expandedComunicado)}
					variant='contained'
					color='primary'
					fullWidth
					size='medium'
					startIcon={!expandedComunicado ? <ChevronRightIcon /> : <ExpandMoreIcon />}
				>
					Configurações do Comunicado
				</Button>
				<Collapse in={expandedComunicado} style={{ margin: '0.5em' }}>
					<ComunicadoTemplate />
				</Collapse>
			</Box>

			<Box>
				<Button
					onClick={() => setExpandedConfiguracaoBoleto(!expandedConfiguracaoBoleto)}
					variant='contained'
					color='primary'
					fullWidth
					size='medium'
					startIcon={!expandedConfiguracaoBoleto ? <ChevronRightIcon /> : <ExpandMoreIcon />}
				>
					Configurações Gerais do Boleto
				</Button>
				<Collapse in={expandedConfiguracaoBoleto} style={{ margin: '0.5em' }}>
					<BoxConfigMarcaDAgua />
					<BoxConfigProprietarioEInquilino />
				</Collapse>
			</Box>
		</Box>
	);
};

export default BoxConfigGeracaoBoleto;
