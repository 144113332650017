import React from 'react';

import { useDataProvider, useNotify } from 'react-admin';
import { Grid } from '@material-ui/core';

import { Controller, FormProvider, useFieldArray } from 'react-hook-form';

import MUIRichTextEditor from 'mui-rte';

import { Box, Checkbox, Divider, FormControlLabel, Tab, TextField, Typography } from '@material-ui/core';
import { Autocomplete, TabContext, TabList } from '@material-ui/lab';

import { NumberFormatBRL, sanitizeListNumberFormatBRLProps } from '../../../../common/CurrencyInput';
import { SyndikosSelectRHF } from '../../../../common/SyndikosSelect';
import SyndkosTabPanel from '../../../../common/TabPanel/SyndkosTabPanel';
import { SyndkosSelectForm, SyndkosSwitchForm } from '../../../../common/inputs/InputsForm/InputsForm';

import { SelectResponsaveisWithValues } from '../../../../common/inputs/SelectUserPessoa/SelectUserPessoa';
import FormInput from './components/FormInput';
import { AmbientesContext, valoresHoras } from './context';

import { makeStyles } from '@material-ui/core/styles';
import InputFile from 'components/common/InputFile';

import { BoxAddConta } from 'components/common/BoxAddConta/index';

const useStyles = makeStyles((theme) => ({
	editor: {
		maxHeight: '195px',
		overflowY: 'auto',
	},
	root: {
		minHeight: '100px',
	},
	fundo: {
		backgroundColor: theme.fundoMenu[100],
	},
}));

const a11yProps = (index) => ({ id: `simple-tab-${index}`, 'aria-controls': `simple-tabpanel-${index}` });

const richTextControls = [
	'title',
	'bold',
	'italic',
	'underline',
	'strikethrough',
	'bulletList',
	'numberList',
	'undo',
	'redo',
	'clear',
	'tags',
];

const AmbienteReservaForm = ({ grupos, methods }) => {
	const [value, setValue] = React.useState(0);
	const { idPlanoCondominio } = React.useContext(AmbientesContext);
	const richTextRefDescricao = React.useRef(null);
	const richTextRefReserva = React.useRef(null);

	const taxaPorUso = methods?.watch('taxa_por_uso');
	const cobrarPorPeriodo = taxaPorUso ? methods?.watch('cobrar_por_periodo') : false;
	const limitarNumeroReservasPorUnidade = methods?.watch('limitar_numero_reservas_por_unidade');
	const classes = useStyles();

	const { fields } = useFieldArray({ control: methods?.control, name: 'dias' });
	const watchFieldArray = methods?.watch('dias');
	const controlledFields = fields.map((field, index) => ({ ...field, ...watchFieldArray[index] }));
	React.useEffect(() => {
		const errors = methods?.formState?.errors;
		if (errors) {
			const errorsObject = Object.keys(errors);
			const tabs = [
				{
					tabIndex: 0,
					validateFields: [
						'taxa_por_uso',
						'conta_receita',
						'reserva_inadimplente',
						'nome',
						'grupos',
						'capacidade_pessoas',
						'dias',
					],
				},
				{
					tabIndex: 1,
					validateFields: [
						'reserva_horas_antes',
						'reserva_dias_antes',
						'cancelamento_horas_antes',
						'maxima_quantidade_unidades_por_periodo',
						'bloquear_domingo',
						'bloquear_sabado',
						'cancelar_cobranca',
						'limitar_numero_reservas_por_unidade',
						'quantidade_maxima_de_reservas',
						'tipo_periodo_maximo_reservas',
					],
				},
			];
			tabs.every((tab) => {
				const tabHasError = tab.validateFields.some((field) => errorsObject.includes(field));
				if (tabHasError) {
					setValue(tab.tabIndex);
					return false;
				}
				return true;
			});
		}
	}, [methods.formState.errors]);

	const DIA_OPTIONS = {
		DOM: 'Domingo',
		SEG: 'Segunda',
		TER: 'Terça',
		QUA: 'Quarta',
		QUI: 'Quinta',
		SEX: 'Sexta',
		SAB: 'Sábado',
	};

	const HORAS_OPTIONS = valoresHoras.map((hora) => ({ id: hora, name: hora.slice(0, 5) }));

	const FRACAO_OPTIONS = [
		{ id: 'HH', name: 'Hora em hora' },
		{ id: 'MP', name: 'Meio período' },
		{ id: 'DI', name: 'Dia inteiro' },
	];

	const TIPO_PERIODOS = [
		{ id: 'S', name: 'Semana' },
		{ id: 'M', name: 'Mês' },
		{ id: 'A', name: 'Ano' },
	];

	return (
		<FormProvider {...methods}>
			<TabContext value={value}>
				<Box width={1}>
					<Box display='flex'>
						<TabList value={value} onChange={(_, newValue) => setValue(newValue)}>
							<Tab label='Configuração Horário' {...a11yProps(0)} value={0} />
							<Tab label='Configuração de Reserva' {...a11yProps(1)} value={1} />
							<Tab label='Regulamentos' {...a11yProps(2)} value={2} />
						</TabList>
					</Box>
					<SyndkosTabPanel value={value} index={0}>
						<Box mt={1}>
							<Box display='flex'>
								<Box flex={1} style={{ minWidth: '150px', alignSelf: 'center' }}>
									<SyndkosSwitchForm
										controllerName='taxa_por_uso'
										control={methods?.control}
										tooltipTitle='O sistema deve fazer lançamentos referente ao uso do ambiente?'
										formControlLabel='Taxa de Uso'
									/>
									<SyndkosSwitchForm
										controllerValue={taxaPorUso ? cobrarPorPeriodo : false}
										controllerName='cobrar_por_periodo'
										control={methods?.control}
										formControlLabel='Cobrança de Taxa de Uso por Período/Hora'
										tooltipTitle='Ao ativar esta opção, o sistema aplicará a cobrança com base nos períodos ou horas selecionados em uma reserva. Para cada período ou hora reservada, será cobrada uma taxa individual.'
										disabled={!taxaPorUso}
									/>
									<SyndkosSwitchForm
										controllerName='reserva_inadimplente'
										control={methods?.control}
										tooltipTitle='Ao habilitar esta opção, o sistema permitirá que usuários inadimplentes realizem reservas.'
										formControlLabel='Unidade inadimplente pode reservar?'
									/>
								</Box>
							</Box>
							<Box display='flex' flexDirection='column' mb={1}>
								<Box display='flex'>
									<Box flexBasis='49%' style={{ margin: '0.688rem 0.938rem 0.313rem 0rem' }}>
										<Controller
											name='conta_receita'
											control={methods?.control}
											render={({ field: { onChange, value } }) => (
												<BoxAddConta
													idPlano={idPlanoCondominio}
													contaReceita={value}
													setContaReceita={onChange}
													disabled={!taxaPorUso}
													tipo='receita'
													filter={{ values: ['O', 'E'], key: 'tipo' }}
													msgErro={
														taxaPorUso
															? methods?.formState?.errors?.conta_receita?.message
															: null
													}
												/>
											)}
										/>
									</Box>
									<Box flexBasis='49%' mt={1}>
										<FormInput
											name='nome'
											label='Nome do Ambiente'
											required
											control={methods?.control}
											setValue={methods?.setValue}
											error={Boolean(methods?.formState?.errors?.nome?.message)}
											helperText={methods?.formState?.errors?.nome?.message}
											fullWidth
										/>
									</Box>
								</Box>
								<Box display='flex'>
									<Box flexBasis='49%' mt={1} mr={2}>
										<Controller
											name='grupos'
											control={methods?.control}
											render={({ field: { onChange, value } }) => (
												<Autocomplete
													disableCloseOnSelect
													multiple
													value={value}
													onChange={(_, v) => {
														onChange(v);
													}}
													getOptionSelected={(option, newValue) => newValue.id === option.id}
													options={grupos}
													getOptionLabel={(option) => option.name}
													renderInput={(params) => (
														<TextField
															{...params}
															label='Grupos'
															variant='outlined'
															error={false}
														/>
													)}
													clearText='Limpar'
													noOptionsText='Nenhum grupo disponível'
													size='small'
													fullWidth
												/>
											)}
										/>
									</Box>
									<Box flexBasis='49%'>
										<FormInput
											name='capacidade_pessoas'
											label='Capacidade (pessoas)'
											type='number'
											inputProps={{ min: 0, max: 32767, style: { minWidth: '200px' } }}
											control={methods?.control}
											setValue={methods?.setValue}
											error={Boolean(methods?.formState?.errors?.capacidade_pessoas?.message)}
											helperText={methods?.formState?.errors?.capacidade_pessoas?.message}
											fullWidth
										/>
									</Box>
								</Box>
							</Box>
							<div
								style={{
									border: '1px solid rgb(207, 194, 194)',
									'border-radius': '3px',
									'margin-top': '20px',
									position: 'relative',
								}}
							>
								<Typography
									component='div'
									variant='caption'
									style={{
										margin: '-15px 0 0 10px',
										padding: '3px',
										display: 'inline-block',
										position: 'absolute',
									}}
									className={classes.fundo}
								>
									Dia e Hora de Reserva
								</Typography>
								<div style={{ padding: '10px' }}>
									{controlledFields.map((item, index) => (
										<>
											<Box display='flex'>
												<Box flex={1} style={{ maxWidth: '115px' }}>
													<Controller
														name={`dias.${index}.habilitado`}
														control={methods?.control}
														render={({ field: { onChange, value } }) => (
															<FormControlLabel
																control={
																	<Checkbox checked={value} onChange={onChange} />
																}
																label={
																	DIA_OPTIONS[
																		methods?.getValues(`dias.${index}.dia`)
																	] || ''
																}
															/>
														)}
													/>
												</Box>
												<Box flex={1}>
													<SyndkosSelectForm
														controllerName={`dias.${index}.hora_1`}
														control={methods?.control}
														selectChoice={HORAS_OPTIONS}
														selectError={Boolean(
															methods?.formState?.errors?.dias?.[index]?.hora_1?.message
														)}
														selectHelperText={
															methods?.formState?.errors?.dias?.[index]?.hora_1?.message
														}
														selectDisabled={!item?.habilitado}
													/>
												</Box>
												<Box
													flex={1}
													style={{
														maxWidth: '30px',
														marginLeft: '10px',
														marginRight: '5px',
														alignSelf: 'center',
													}}
												>
													<Typography variant='caption'>Até</Typography>
												</Box>
												<Box flex={1}>
													<SyndkosSelectForm
														controllerName={`dias.${index}.hora_2`}
														control={methods?.control}
														selectChoice={HORAS_OPTIONS}
														selectError={Boolean(
															methods?.formState?.errors?.dias?.[index]?.hora_2?.message
														)}
														selectHelperText={
															methods?.formState?.errors?.dias?.[index]?.hora_2?.message
														}
														selectDisabled={!item?.habilitado}
													/>
												</Box>
												<Box
													flex={1}
													style={{
														maxWidth: '10px',
														marginLeft: '10px',
														marginRight: '5px',
														alignSelf: 'center',
													}}
												>
													<Typography variant='caption'>/</Typography>
												</Box>
												<Box flex={1}>
													<SyndkosSelectForm
														controllerName={`dias.${index}.hora_3`}
														control={methods?.control}
														selectChoice={HORAS_OPTIONS}
														selectError={Boolean(
															methods?.formState?.errors?.dias?.[index]?.hora_3?.message
														)}
														selectHelperText={
															methods?.formState?.errors?.dias?.[index]?.hora_3?.message
														}
														selectDisabled={!item?.habilitado}
													/>
												</Box>
												<Box
													flex={1}
													style={{
														maxWidth: '30px',
														marginLeft: '10px',
														marginRight: '5px',
														alignSelf: 'center',
													}}
												>
													<Typography variant='caption'>Até</Typography>
												</Box>
												<Box flex={1}>
													<SyndkosSelectForm
														controllerName={`dias.${index}.hora_4`}
														control={methods?.control}
														selectChoice={HORAS_OPTIONS}
														selectError={Boolean(
															methods?.formState?.errors?.dias?.[index]?.hora_4?.message
														)}
														selectHelperText={
															methods?.formState?.errors?.dias?.[index]?.hora_4?.message
														}
														selectDisabled={!item?.habilitado}
													/>
												</Box>
												<Box flex={1} style={{ marginLeft: '10px' }}>
													<Controller
														name={`dias.${index}.fracao`}
														control={methods?.control}
														render={({ field: { onChange, value } }) => (
															<SyndikosSelectRHF
																source={`dias.${index}.fracao`}
																label='Fração'
																value={value}
																allowEmpty={false}
																choices={FRACAO_OPTIONS}
																defaultValue={
																	FRACAO_OPTIONS.filter((v) => v.id === value)[0]
																}
																onChange={onChange}
																error={Boolean(
																	methods?.formState?.errors?.dias?.[index]?.fracao
																		?.message
																)}
																helpertext={
																	methods?.formState?.errors?.dias?.[index]?.fracao
																		?.message
																}
																disabled={!item?.habilitado}
																variant='outlined'
																disableClearable
																inputProps={{ style: { maxHeight: '10px' } }}
																fullWidth
															/>
														)}
													/>
												</Box>
												<Box flex={1} style={{ marginLeft: '10px', marginTop: '8px' }}>
													<Controller
														name={`dias.${index}.valor`}
														control={methods?.control}
														render={({ field: { onChange } }) => (
															<TextField
																name='valor'
																label='Valor'
																control={methods?.control}
																setValue={methods?.setValue}
																error={Boolean(
																	methods?.formState?.errors?.dias?.[index]?.valor
																		?.message
																)}
																helperText={
																	!item?.habilitado
																		? ''
																		: methods?.formState?.errors?.dias?.[index]
																				?.valor?.message
																}
																value={
																	taxaPorUso && item?.habilitado
																		? methods?.getValues(`dias.${index}.valor`) || 0
																		: 0
																}
																inputProps={{
																	...sanitizeListNumberFormatBRLProps({
																		prefix: 'R$ ',
																		decimalScale: 2,
																		style: { minWidth: '200px' },
																	}),
																	style: { height: '10px' },
																}}
																InputProps={{ inputComponent: NumberFormatBRL }}
																InputLabelProps={{
																	shrink: true,
																}}
																disabled={!taxaPorUso || !item?.habilitado}
																fullWidth
																size='small'
																onChange={onChange}
															/>
														)}
													/>
												</Box>
											</Box>
											<Box
												display={
													methods?.formState?.errors?.dias?.[index]?.hora_1?.message
														? 'flex'
														: 'none'
												}
											>
												<Typography variant='caption' style={{ color: '#f44336' }}>
													{methods?.formState?.errors?.dias?.[index]?.hora_1?.message}
												</Typography>
											</Box>
											<Box
												display={
													methods?.formState?.errors?.dias?.[index]?.hora_2?.message
														? 'flex'
														: 'none'
												}
											>
												<Typography variant='caption' style={{ color: '#f44336' }}>
													{methods?.formState?.errors?.dias?.[index]?.hora_2?.message}
												</Typography>
											</Box>
											<Box
												display={
													methods?.formState?.errors?.dias?.[index]?.hora_3?.message
														? 'flex'
														: 'none'
												}
											>
												<Typography variant='caption' style={{ color: '#f44336' }}>
													{methods?.formState?.errors?.dias?.[index]?.hora_3?.message}
												</Typography>
											</Box>
											<Box
												display={
													methods?.formState?.errors?.dias?.[index]?.hora_4?.message
														? 'flex'
														: 'none'
												}
											>
												<Typography variant='caption' style={{ color: '#f44336' }}>
													{methods?.formState?.errors?.dias?.[index]?.hora_4?.message}
												</Typography>
											</Box>
										</>
									))}
								</div>
							</div>
						</Box>
					</SyndkosTabPanel>
					<SyndkosTabPanel value={value} index={1}>
						<Box sx={{ display: 'flex' }}>
							<Box flex={1}>
								<Box display='flex' style={{ alignItems: 'center' }}>
									<Typography variant='body1'>Reserva com no mínimo</Typography>
									<FormInput
										name='reserva_horas_antes'
										label={false}
										type='number'
										inputProps={{ min: 0, max: 96, style: { minWidth: '50px' } }}
										control={methods?.control}
										setValue={methods?.setValue}
										error={Boolean(methods?.formState?.errors?.reserva_horas_antes?.message)}
										helperText={methods?.formState?.errors?.reserva_horas_antes?.message}
										style={{ marginLeft: '5px', marginRight: '5px' }}
									/>
									<Typography variant='body1'>HORA(S) antes do evento.</Typography>
								</Box>
								<Box display='flex' style={{ alignItems: 'center' }}>
									<Typography variant='body1'>Reserva com no máximo</Typography>
									<FormInput
										name='reserva_dias_antes'
										label={false}
										type='number'
										inputProps={{ min: 0, max: 365, style: { minWidth: '50px' } }}
										control={methods?.control}
										setValue={methods?.setValue}
										error={Boolean(methods?.formState?.errors?.reserva_dias_antes?.message)}
										helperText={methods?.formState?.errors?.reserva_dias_antes?.message}
										style={{ marginLeft: '5px', marginRight: '5px' }}
									/>
									<Typography variant='body1'>DIA(S) antes do evento.</Typography>
								</Box>
								<Box display='flex' style={{ alignItems: 'center' }}>
									<Typography variant='body1'>Cancelamento com até</Typography>
									<FormInput
										name='cancelamento_horas_antes'
										label={false}
										type='number'
										inputProps={{ min: 0, max: 96, style: { minWidth: '50px' } }}
										control={methods?.control}
										setValue={methods?.setValue}
										error={Boolean(methods?.formState?.errors?.cancelamento_horas_antes?.message)}
										helperText={methods?.formState?.errors?.cancelamento_horas_antes?.message}
										style={{ marginLeft: '5px', marginRight: '5px' }}
									/>
									<Typography variant='body1'>HORA(S) antes do evento.</Typography>
								</Box>
								<Box display='flex' style={{ alignItems: 'center' }}>
									<Typography variant='body1'>Reserva com no máximo</Typography>
									<FormInput
										name='maxima_quantidade_unidades_por_periodo'
										label={false}
										type='number'
										inputProps={{ min: 0, max: 999, style: { minWidth: '50px' } }}
										control={methods?.control}
										setValue={methods?.setValue}
										error={Boolean(
											methods?.formState?.errors?.maxima_quantidade_unidades_por_periodo?.message
										)}
										helperText={
											methods?.formState?.errors?.maxima_quantidade_unidades_por_periodo?.message
										}
										style={{ marginLeft: '5px', marginRight: '5px' }}
									/>
									<Typography variant='body1'>UNIDADE(S) na mesma Reserva.</Typography>
								</Box>
								<Box display='flex'>
									<Box flex={1} mr='1em'>
										<SyndkosSwitchForm
											controllerName='bloquear_domingo'
											control={methods?.control}
											tooltipTitle='Reservou o Sábado, bloqueia o Domingo Posterior'
											formControlLabel='Reservou o Sábado, bloqueia o Domingo Posterior'
										/>
									</Box>
								</Box>
								<Box display='flex'>
									<Box flex={1} mr='1em'>
										<SyndkosSwitchForm
											controllerName='bloquear_sabado'
											control={methods?.control}
											tooltipTitle='Reservou o Domingo, bloqueia o Sábado anterior'
											formControlLabel='Reservou o Domingo, bloqueia o Sábado anterior'
										/>
									</Box>
								</Box>
								<Box display='flex'>
									<Box flex={1} mr='1em'>
										<SyndkosSwitchForm
											controllerName='cancelar_cobranca'
											control={methods?.control}
											tooltipTitle='Cancelar cobrança de taxa para cancelamento dentro do prazo'
											formControlLabel='Cancelar cobrança de taxa para cancelamento dentro do prazo'
										/>
									</Box>
								</Box>
								<Box display='flex'>
									<Box flex={1} mr='1em'>
										<SyndkosSwitchForm
											controllerName='limitar_numero_reservas_por_unidade'
											control={methods?.control}
											tooltipTitle='Quantidade de reservas permitidas para unidade'
											formControlLabel='Quantidade de reservas permitidas para unidade'
										/>
									</Box>
								</Box>
								<Box display='flex' style={{ alignItems: 'center' }}>
									<FormInput
										name='quantidade_maxima_de_reservas'
										label='Quantidade de Reservas'
										type='number'
										inputProps={{ min: 0, max: 96, style: { minWidth: '50px' } }}
										control={methods?.control}
										setValue={methods?.setValue}
										error={Boolean(
											methods?.formState?.errors?.quantidade_maxima_de_reservas?.message
										)}
										helperText={methods?.formState?.errors?.quantidade_maxima_de_reservas?.message}
										style={{ marginLeft: '8px', marginRight: '8px', width: '45%' }}
										disabled={!limitarNumeroReservasPorUnidade}
									/>
									<Typography variant='body1'>por</Typography>
									<Controller
										name='tipo_periodo_maximo_reservas'
										control={methods?.control}
										render={({ field: { onChange, value } }) => (
											<SyndikosSelectRHF
												source='tipo_periodo_maximo_reservas'
												label='Semana/Mês/Ano'
												value={value}
												choices={TIPO_PERIODOS}
												defaultValue={TIPO_PERIODOS.filter((t) => t.id === value)[0]}
												onChange={onChange}
												error={
													methods?.formState?.errors?.tipo_periodo_maximo_reservas?.message
												}
												touched={
													Boolean(limitarNumeroReservasPorUnidade) &&
													Boolean(
														methods?.formState?.errors?.tipo_periodo_maximo_reservas
															?.message
													)
												}
												variant='outlined'
												disableClearable
												disabled={!limitarNumeroReservasPorUnidade}
												style={{ marginLeft: '8px', marginRight: '8px', width: '45%' }}
											/>
										)}
									/>
								</Box>
							</Box>
							<Divider orientation='vertical' flexItem light={true} />
							<Box flex={1} ml='1em'>
								<Box display='flex' style={{ alignItems: 'center' }}>
									<Controller
										name='responsaveis'
										control={methods?.control}
										render={({ field: { onChange, value } }) => {
											const [valor, setValor] = React.useState(value);

											React.useEffect(() => {
												onChange(valor);
											}, [valor]);

											return <SelectResponsaveisWithValues value={valor} onChange={setValor} />;
										}}
									/>
								</Box>
							</Box>
						</Box>
					</SyndkosTabPanel>
					<SyndkosTabPanel value={value} index={2}>
						<Box>
							<Box>
								<Typography variant='h6'>Descrição da Reserva</Typography>
								<Divider light={true} />
								<MUIRichTextEditor
									defaultValue={methods?.getValues?.('descricao_json') || ''}
									name='descricao_json'
									ref={richTextRefDescricao}
									onSave={(a) => methods?.setValue('descricao_json', a)}
									onChange={(e) => richTextRefDescricao.current.save()}
									controls={richTextControls}
									classes={{
										root: classes.root,
										editor: classes.editor,
									}}
									inlineToolbar
									toolbarButtonSize='small'
								/>
							</Box>

							<Box>
								<Typography variant='h6'>Regulamento da Reserva</Typography>
								<Divider light={true} />
								<MUIRichTextEditor
									name='regulamento_json'
									defaultValue={methods?.getValues?.('regulamento_json') || ''}
									ref={richTextRefReserva}
									onSave={(a) => methods?.setValue('regulamento_json', a)}
									onChange={(e) => richTextRefReserva.current.save()}
									controls={richTextControls}
									classes={{
										root: classes.root,
										editor: classes.editor,
									}}
									inlineToolbar
									toolbarButtonSize='small'
								/>
							</Box>

							<Box width='40rem'>
								<Controller
									name='write_only_anexo_pdf'
									id='write_only_anexo_pdf'
									render={({ field: { onChange, value } }) => {
										return (
											<InputFile
												source='write_only_anexo_pdf'
												name='write_only_anexo_pdf'
												label='Arquivo em anexo'
												variant='outlined'
												PermitedFileExtension={['pdf']}
												onCallBack={(e, dataUrl) => {
													onChange(dataUrl);
												}}
												fileDataUrl={value}
											/>
										);
									}}
								/>
							</Box>
						</Box>
					</SyndkosTabPanel>
				</Box>
			</TabContext>
		</FormProvider>
	);
};

export default AmbienteReservaForm;
