import { subYears } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import React, { useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { format as formatFns } from 'date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useInput } from 'react-admin';

const maxDate = subYears(new Date().setHours(10, 0, 0), 18);

export const SyndikosMuiPickersUtilsProvider = (props) => (
	<MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
		{props.children}
	</MuiPickersUtilsProvider>
);

export const RawKBDatePicker = ({
	minDateMessage = 'Não pode passar da data mínima!',
	maxDateMessage = 'Não pode passar da data máxima!',
	invalidDateMessage = 'Data inválida!',
	...props
}) => (
	<SyndikosMuiPickersUtilsProvider>
		<KeyboardDatePicker
			minDateMessage={minDateMessage}
			maxDateMessage={maxDateMessage}
			invalidDateMessage={invalidDateMessage}
			{...props}
		/>
	</SyndikosMuiPickersUtilsProvider>
);

export const SyndikosKeyboardDatePicker = ({ format, maxDate, maxDateMessage, handleChange, required, ...props }) => {
	const {
		input: { name, onChange, value, ...rest },
		meta: { touched, error },
	} = useInput(props);
	const [dt, setDt] = useState(null);
	const handleValueChange = () => {
		if (value && value.match && value.match(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])/)) {
			setDt(value.split('T')[0] + 'T10:00');
		} else if (value && typeof value == 'object' && value.toDateString && value.toDateString() !== 'Invalid Date') {
			setDt(formatFns(value, 'yyyy-MM-dd') + 'T10:00');
		}
	};
	useEffect(handleValueChange, [value]);

	return (
		<KeyboardDatePicker
			disableToolbar
			{...props}
			format={format}
			maxDate={maxDate}
			maxDateMessage={maxDateMessage}
			margin='dense'
			variant='inline'
			inputVariant='outlined'
			KeyboardButtonProps={{
				'aria-label': 'change date',
			}}
			value={dt}
			name={name}
			onChange={(event) => {
				handleChange ? handleChange(event, onChange) : onChange(event);
			}}
			required={required}
			error={!!(touched && error)}
			helperText={touched && error}
			{...rest}
		/>
	);
};

export const InputDate = (props) => {
	return (
		<SyndikosMuiPickersUtilsProvider>
			<SyndikosKeyboardDatePicker
				maxDate={maxDate}
				maxDateMessage='Usuário precisa ter pelo menos 18 anos'
				label='Data de nascimento'
				{...props}
				format='dd/MM/yyyy'
				id='date-picker-inline'
				mask='__/__/____'
			/>
		</SyndikosMuiPickersUtilsProvider>
	);
};

export const InputDateReferencia = ({ alwaysOn, allowEmpty, label = 'Referência', ...props }) => {
	const initialFocusedDate = new Date();
	initialFocusedDate.setDate(1);
	return (
		<SyndikosMuiPickersUtilsProvider>
			<SyndikosKeyboardDatePicker
				{...props}
				format='MM/yyyy'
				id='date-picker-inline-referencia'
				label={label}
				views={['year', 'month']}
				mask='__/____'
				initialFocusedDate={initialFocusedDate}
			/>
		</SyndikosMuiPickersUtilsProvider>
	);
};

export function FormlessInputDateReferencia({ value, onChange, ...props }) {
	return (
		<SyndikosMuiPickersUtilsProvider>
			<KeyboardDatePicker
				margin='dense'
				variant='inline'
				inputVariant='outlined'
				format='MM/yyyy'
				views={['year', 'month']}
				mask='__/____'
				value={value || null}
				onChange={(date) => onChange && onChange(date)}
				{...props}
			/>
		</SyndikosMuiPickersUtilsProvider>
	);
}
