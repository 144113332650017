import React from 'react';

import { ArrayInput, BooleanInput, required, TextInput, useInput } from 'react-admin';

import { Box, makeStyles, Paper, Typography } from '@material-ui/core';

import { FormularioBox } from '../../common/Formulario';
import { SyndikosSelect } from '../../common/SyndikosSelect';
import { CustomRemoveButton } from '../../common/buttons/BotaoRemover';
import { EnderecoBox } from '../../common/Endereco';

import ImageFormInput from '../../common/inputs/FileInput/ImageFormInput';
import { InputDocumento } from '../../common/InputDocumento';
import { validacaoEmailObrigatorio } from '../../../fieldControl/email';
import { nomeParser, nomeValidacao } from '../../../fieldControl/nome';
import { telefoneFormatacao, telefoneParser, telefoneValidacao } from '../../../fieldControl/telefone';
import { CustomFormIterator } from 'components/common/CustomFormIterator';

const opcoesTipoTelefone = [
	{ id: 'M', name: 'Celular' },
	{ id: 'R', name: 'Residencial' },
	{ id: 'C', name: 'Comercial' },
	{ id: 'P', name: 'Principal' },
	{ id: 'O', name: 'Outros' },
];

const opcoesTipoEmail = [
	{ id: 'P', name: 'Pessoal' },
	{ id: 'C', name: 'Comercial' },
	{ id: 'CB', name: 'Cobrança' },
	{ id: 'O', name: 'Outros' },
];

const useEscritorioStyle = makeStyles(() => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
	},
	container_row: {
		display: 'flex',
		flexDirection: 'row',
	},
	dados_cadastrais: {
		padding: '16px',
	},
	dados_cadastrais_inputs: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
	},
	dados_cadastrais__administradora: {
		display: 'flex',
	},
	dados_cadastrais__administradora_inputs: {
		display: 'flex',
		flexDirection: 'column',
		paddingRight: '1rem',
	},
	dados_cadastrais__administradora_inputs__input: {
		display: 'flex',
		columnGap: '1rem',
		flexDirection: 'row',
		width: '100%',
	},
	dados_cadastrais__administradora__logo: {
		display: 'flex',
		flexDirection: 'column',
		textAlign: 'center',
	},
	dados_cadastrais__administradora__marca_dagua: {
		display: 'flex',
		flexDirection: 'column',
		textAlign: 'center',
	},
	dados_contato__container: {
		width: '650px',
		padding: '16px',
	},
	dados_endereco: {
		width: '650px',
		padding: '16px',
	},
	dados_contato: {
		display: 'flex',
	},
	gap: { gap: '0.2rem' },
}));

export const EscritorioFormulario = (props) => {
	const style = useEscritorioStyle();

	const newProps = React.useMemo(() => {
		const {
			record: { limite_condominio, limite_condominio_kondomynos, ...record },
			...newProps
		} = props;

		newProps.record = record;
		return newProps;
	});

	return (
		<Paper padding='1em'>
			<FormularioBox {...newProps} display='block' noBackButton={true} showFormHeader={() => false}>
				<Box className={style.container}>
					<Box className={style.dados_cadastrais}>
						<Typography variant='h5' gutterBottom>
							Dados Cadastrais
						</Typography>

						<hr
							style={{
								marginRight: '1em',
								borderTop: '#d3d3d3',
							}}
						></hr>

						<Box className={style.dados_cadastrais__administradora}>
							<Box className={style.dados_cadastrais_inputs}>
								<Box className={style.dados_cadastrais__administradora_inputs}>
									<Box className={style.dados_cadastrais__administradora_inputs__input}>
										<TextInput
											source='razao_administradora'
											variant='outlined'
											validate={nomeValidacao}
											parse={nomeParser}
											fullWidth
										/>
										<TextInput
											source='apelido_administradora'
											variant='outlined'
											validate={nomeValidacao}
											parse={nomeParser}
											fullWidth
										/>
									</Box>
									<Box>
										<Box
											display='flex'
											className={style.dados_cadastrais__administradora_inputs__input}
										>
											<InputDocumento
												source='documento'
												sourceTipo='tipo_administradora'
												variant='outlined'
												label='Tipo Administradora'
												validate={false}
												br={false}
												fullWidth
												flexDirection='row'
											/>
										</Box>
									</Box>
								</Box>
								<Box display='flex' className={style.gap}>
									<RequerSenhaBoletoInput source='requer_senha_boleto' />
								</Box>
							</Box>
							<Box className={style.dados_cadastrais__administradora__logo}>
								<ImageFormInput source='logo' label={'Logo Escritório'} />
							</Box>
							<Box className={style.dados_cadastrais__administradora__marca_dagua}>
								<ImageFormInput label="Marca d'água" source='marca_dagua' />
							</Box>
						</Box>
					</Box>
					<Box className={style.container_row}>
						<EnderecoBox
							className={style.dados_endereco}
							labelTitle='Dados de Endereçamento'
							{...newProps}
						/>
						<Box className={style.dados_contato__container}>
							<Typography variant='h5' gutterBottom>
								Contato
							</Typography>

							<hr
								style={{
									marginRight: '1em',
									borderTop: '#d3d3d3',
								}}
							></hr>
							<Box className={style.dados_contato}>
								<ArrayInput
									source='telefone_administradora'
									label='Telefones adicionais'
									variant='outlined'
								>
									<CustomFormIterator removeButton={<CustomRemoveButton />}>
										<SyndikosSelect
											source='tipo'
											label='Tipo de Telefone'
											choices={opcoesTipoTelefone}
											validate={required()}
											fullWidth
											delay={0}
										/>
										<TextInput
											source='telefone'
											label='Telefone'
											format={telefoneFormatacao}
											parse={telefoneParser}
											validate={[required(), telefoneValidacao]}
											fullWidth
										/>
									</CustomFormIterator>
								</ArrayInput>
								<ArrayInput source='email_administradora' label='Emails adicionais' variant='outlined'>
									<CustomFormIterator removeButton={<CustomRemoveButton />}>
										<SyndikosSelect
											source='tipo'
											label='Tipo de Email'
											validate={required()}
											choices={opcoesTipoEmail}
											fullWidth
											delay={0}
										/>
										<TextInput
											source='email'
											label='Email'
											validate={validacaoEmailObrigatorio}
											fullWidth
										/>
									</CustomFormIterator>
								</ArrayInput>
							</Box>
						</Box>
					</Box>
				</Box>
			</FormularioBox>
		</Paper>
	);
};

const RequerSenhaBoletoInput = ({ ...rest }) => {
	const { input } = useInput(rest);

	return (
		<BooleanInput
			{...input}
			options={{ checked: input.value }}
			initialValue={false}
			label='Requer senha para visualização de boletos enviados por e-mail'
		/>
	);
};
