import React from 'react';

import { useNotify, useDataProvider, useRefresh } from 'react-admin';
import { ModalSizeEditableContext } from 'components/common/ModalSizeEditableContext';
import LoginComponentBancoCora from 'components/resources/condominios/ModalEditaContaBancaria/bancos/cora_403/ContaCorrenteProps/componentes/LoginComponent';

const onSuccessCallbackDefault = (response) => {};

export const useBaixarRemessas = ({
	idsRegistrosSelecionados,
	referencia,
	id_identificacao_arrecadacao,
	contaBDestinoId,
	condominioSelecionadoId,
	onSuccessCallback = onSuccessCallbackDefault,
	parametrosPayloadExtras,
}) => {
	delete parametrosPayloadExtras?.password;
	delete parametrosPayloadExtras?.username;
	const notify = useNotify();
	const dataProvider = useDataProvider();
	const refresh = useRefresh();
	const { setModalValue } = React.useContext(ModalSizeEditableContext);
	const data = {
		lista_contas_a_receber_id: idsRegistrosSelecionados,
		referencia: referencia,
		identificacao_arrecadacao_id: id_identificacao_arrecadacao,
		conta_bancaria_id: contaBDestinoId,
		condominio_id: condominioSelecionadoId,
		...parametrosPayloadExtras,
	};
	const baixarRemessas = React.useCallback(() => {
		return dataProvider
			.safeCreate('remessas/download', {
				data: data,
			})
			.then(onSuccessCallback)
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);

				const erroMsg = Object.values(e?.response?.data || {})[0] || [
					'Erro inesperado, tente recarregar a página',
				];
				if (
					e?.response?.data.message.includes(
						'Insira suas credenciais para obter acesso e gerar os boletos via API'
					)
				) {
					setModalValue((v) => ({
						...v,
						open: true,
						dialogBody: (
							<LoginComponentBancoCora
								data={data}
								dataProvider={dataProvider}
								setModalValue={setModalValue}
								notify={notify}
								refresh={refresh}
							/>
						),
					}));
					return;
				}

				notify(erroMsg, 'warning');

				return e?.response?.data;
			});
	}, [
		notify,
		dataProvider,
		referencia,
		id_identificacao_arrecadacao,
		contaBDestinoId,
		condominioSelecionadoId,
		idsRegistrosSelecionados,
		parametrosPayloadExtras,
	]);

	return baixarRemessas;
};

export default useBaixarRemessas;
