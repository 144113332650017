import React from 'react';
import { ModalSizeEditableContext } from 'components/common/ModalSizeEditableContext';
import { Close, Check, ChevronLeft } from '@material-ui/icons';
import { Button, DialogTitle, DialogContent, DialogActions, Typography, Tooltip } from '@material-ui/core';
import { CondominiosContext } from 'context/CondominioContextProvider';
import { List, Pagination, TextField, useNotify, Confirm, useRefresh, FunctionField } from 'react-admin';
import dataProvider from 'dataProvider';
import { Tabela } from 'components/common/Tabela';
import { NumberFormatBRL } from 'components/common/CurrencyInput';
import ModalLeiturasAplicativo from './ModalLeiturasAplicativo';
import { UserThemeContext } from 'theme/context';
import { parse, format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import ImageRoundedIcon from '@material-ui/icons/ImageRounded';
import ImagemLeitura from './componentes/ImagemLeitura';

const ValorField = ({ record, prefix = 'R$ ' }) => (
	<NumberFormatBRL
		prefix={`${prefix}${(record?.valor || 0) < 0 ? '-' : ''}`}
		displayType='text'
		value={record?.valor || 0}
		onChange={() => {}}
	/>
);

const useAprovar = (data) => {
	const { setModalValue } = React.useContext(ModalSizeEditableContext);
	const notify = useNotify();
	const refresh = useRefresh();

	const handleAprovar = React.useCallback(() => {
		dataProvider
			.updateMany('aprova_leituras_cadastradas_aplicativo', { data: { ids: data } })
			.then(() => {
				setModalValue((v) => ({ ...v, open: false }));
				notify('Lancamentos de Leituras aprovadas com sucesso', { type: 'success' });
				refresh();
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				return notify('Ocorreu um problema ao realizar o cadastro.', { type: 'error' });
			});
	}, [data]);

	return handleAprovar;
};

const AprovarButton = ({ record }) => {
	const { theme } = React.useContext(UserThemeContext);

	const [open, setOpen] = React.useState(false);
	const [data, setData] = React.useState(null);

	const handleOpenConfirm = (data) => {
		setData(data);
		setOpen(true);
	};

	return (
		<div>
			<Button
				onClick={() => handleOpenConfirm(record.ids_lancamentos_leituras)}
				size='small'
				style={{
					backgroundColor: theme == 'light' ? '#2F345D' : '#585C7D',
					color: '#FFFFFF',
					padding: 10,
					marginTop: '1em',
				}}
			>
				APROVAR TODOS OS LANÇAMENTOS
			</Button>
			<ConfirmarAcao open={open} onOpen={setOpen} data={data} />
		</div>
	);
};

const ConfirmarAcao = ({ open, onOpen, data }) => {
	const { theme } = React.useContext(UserThemeContext);
	const handleAprovar = useAprovar(data);

	const handleDialogClose = () => onOpen(false);
	const handleConfirm = () => {
		handleAprovar();
		onOpen(false);
	};

	return (
		<Confirm
			isOpen={open}
			title={'Confirmação de Aprovação'}
			content={
				<span style={{ color: theme == 'light' ? '#000000' : '#FFFFFF' }}>
					Esta ação irá aprovar todas as leituras selecionadas
				</span>
			}
			onConfirm={handleConfirm}
			ConfirmIcon={Check}
			onClose={handleDialogClose}
			CancelIcon={Close}
		/>
	);
};

const TabelaLancamentoLeituras = (props) => {
	const [showImage, setShowImage] = React.useState(false);
	const [imagePath, setImagePath] = React.useState(null);

	const handleClickImage = (record) => {
		if (record.foto) {
			setImagePath(record.foto);
			setShowImage(true);
		}
	};

	return (
		<>
			<Tabela {...props} disableClickRow>
				<TextField source='descricao' label='Descrição' />
				<TextField source='grupo' label='Grupo' />
				<TextField source='unidade' label='Unidade' />
				<TextField source='ultima_leitura' label='Última Leitura' align='right' />
				<TextField source='leitura' label='Leitura' align='right' />
				<TextField source='consumo' label='Consumo' align='right' />
				<ValorField source='valor' label='Valor' align='right' />
				<FunctionField
					label='Ação'
					render={(record) =>
						record.foto ? (
							<Typography
								onClick={() => handleClickImage(record)}
								style={{ display: 'flex', marginLeft: '7px', cursor: 'pointer' }}
								variant='div'
							>
								<Tooltip title='Visualizar Foto da Leitura' placement='bottom'>
									<ImageRoundedIcon />
								</Tooltip>
							</Typography>
						) : null
					}
				/>
			</Tabela>
			{showImage && <ImagemLeitura imagePath={imagePath} setShowImage={setShowImage} />}
		</>
	);
};

const ModalLancamentosLeiturasAplicativo = ({ record }) => {
	const { setModalValue, setMinWidthModel } = React.useContext(ModalSizeEditableContext);
	const { condominioSelecionado } = React.useContext(CondominiosContext);

	const handleClose = React.useCallback(() => {
		setModalValue((v) => ({ ...v, open: false }));
	}, [setModalValue]);

	return (
		<>
			<DialogTitle>{condominioSelecionado?.apelido} - Leituras Lançadas pelo Aplicativo</DialogTitle>
			<DialogContent>
				<div style={{ marginBottom: '20px' }}>
					<strong>
						{record.nome} - Ref.{' '}
						{format(parse(record.referencia, 'yyyy-MM-dd', new Date()), 'MM/yyyy', { locale: ptBR })}
					</strong>
				</div>
				<List
					basePath='/lancamentos_leituras_cadastradas_aplicativo'
					resource='lancamentos_leituras_cadastradas_aplicativo'
					title='Lancamento Leituras'
					filter={{
						id_leitura: record.id_leitura,
						referencia: record.referencia,
						id_condominio: condominioSelecionado.id,
					}}
					sort={{ field: 'unidade', order: 'ASC' }}
					bulkActionButtons={false}
					perPage={10}
					exporter={false}
					empty={false}
					pagination={
						<Pagination
							rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 500]}
							labelRowsPerPage='Lançamentos Leituras por página'
						/>
					}
					syncWithLocation={false}
					actions={false}
				>
					<TabelaLancamentoLeituras />
				</List>
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<AprovarButton record={record} />
				</div>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						setMinWidthModel('50vw');
						setModalValue((v) => ({
							...v,
							open: true,
							dialogBody: <ModalLeiturasAplicativo />,
						}));
					}}
					size='small'
					startIcon={<ChevronLeft />}
					variant='text'
				>
					VOLTAR
				</Button>
				<Button onClick={handleClose} size='small' startIcon={<Close />} variant='text'>
					SAIR
				</Button>
			</DialogActions>
		</>
	);
};

export default ModalLancamentosLeiturasAplicativo;
