import React from 'react';

import { useDataProvider, useNotify, useRefresh } from 'react-admin';

import { Link } from 'react-router-dom';

import { Typography } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';

import { format, isMatch, parseISO } from 'date-fns';

import { CondominiosContext } from 'context/CondominioContextProvider';
import { ModalSizeEditableContext } from 'components/common/ModalSizeEditableContext';
import { ArrecadacoesContext } from '../../../ArrecadacoesContext';
import { BoletosContext } from '../BoletosContextProvider';
import { ModalConfiguracaoImpressao, ModalErrosReportBoletos } from '../modais';
import { ButtonConfirmV2 } from 'components/common/buttons/ButtonConfirmV2';

export const ButtonGerarBoletos = ({
	idsRegistrosSelecionados,
	disabled,
	setLoadingRequest,
	label = 'Gerar Boletos',
	configurar,
}) => {
	const notify = useNotify();
	const { setModalValue } = React.useContext(ModalSizeEditableContext);
	const { referencia, id_identificacao_arrecadacao, contaBDestinoId } = React.useContext(ArrecadacoesContext);
	const { condominioSelecionado } = React.useContext(CondominiosContext);
	const condominioSelecionadoId = condominioSelecionado?.id;
	const {
		init,
		opcoesImpressao,
		datasPrestacao,
		dataEnvioBackendDevedores,
		modeloDemonstrativo,
		modeloDemonstrativoVerso,
		boletoConfigIsValid,
		comunicado,
		datasDemonstrativoMensal,
		dateRange,
		comunicadoId,
	} = React.useContext(BoletosContext);
	const dataProvider = useDataProvider();
	const refresh = useRefresh();
	const [valid, setValid] = React.useState(false);

	React.useEffect(() => {
		setValid(
			idsRegistrosSelecionados &&
				idsRegistrosSelecionados.length &&
				id_identificacao_arrecadacao &&
				contaBDestinoId &&
				condominioSelecionadoId &&
				referencia &&
				isMatch(referencia, 'yyyy-MM-dd')
		);
	}, [
		setValid,
		idsRegistrosSelecionados,
		id_identificacao_arrecadacao,
		contaBDestinoId,
		condominioSelecionadoId,
		referencia,
		opcoesImpressao,
		dataEnvioBackendDevedores,
		modeloDemonstrativo,
		comunicado,
		modeloDemonstrativoVerso,
	]);

	const handleVerifyGeraBoleto = () => {
		if (comunicado && !opcoesImpressao.imprimir_comunicado) {
			notify('Você selecionou um Comunicado mas não selecionou Apresentar Comunicado', 'warning');
			return;
		}
		if (!comunicado && opcoesImpressao.imprimir_comunicado) {
			notify('Você selecionou Apresentar Comunicado mas não selecionou um Comunicado', 'warning');
			return;
		}
		if (
			modeloDemonstrativo?.id === 'DM' ||
			modeloDemonstrativo?.id === 'AM' ||
			(opcoesImpressao.imprimir_demonstrativo_verso && modeloDemonstrativoVerso?.id === 'DM')
		) {
			const startDate = new Date(dateRange.startDate);
			const endDate = new Date(dateRange.endDate);
			const diffInMonths =
				(endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth());
			if (diffInMonths > 2) {
				notify('Você não pode gerar o Demonstrativo Mensal com um período maior que 3 meses', 'warning');
				return;
			}
		}
		handleEnviar();
	};

	const handleEnviar = () => {
		setLoadingRequest(true);
		dataProvider
			.simplePost('boletos/gerar_boletos', {
				data: {
					...opcoesImpressao,
					...datasPrestacao,
					...dataEnvioBackendDevedores,
					...datasDemonstrativoMensal,
					list_ids_conta_a_receber: idsRegistrosSelecionados,
					referencia: format(parseISO(referencia), 'MM-yyyy'),
					id_identificacao: id_identificacao_arrecadacao,
					id_conta_bancaria_provisionada: contaBDestinoId,
					id_condominio: condominioSelecionado?.id,
					identificacao_arrecadacao_id: id_identificacao_arrecadacao,
					texto_comunicado: comunicado,
					comunicado_id: comunicadoId,
					modelo_demonstrativo: modeloDemonstrativo,
					modelo_demonstrativo_verso: modeloDemonstrativoVerso,
				},
			})
			.then(({ data }) => {
				if (!data) {
					notify('Erro inesperado, tente recarregar a página', 'warning');
					return;
				}
				if (typeof data === 'object') {
					const { message, errors_validacao, lista_ids } = data;

					if (message) notify(message || '');

					if (errors_validacao) {
						const dict_tipos_linhas = {
							unidade: 'A unidade',
							propetario: 'O proprietário',
							inquilino: 'O inquilino',
							pessoa: 'A pessoa',
							boleto: 'O boleto',
						};
						setModalValue((v) => ({
							...v,
							open: true,
							dialogBody: (
								<ModalErrosReportBoletos
									idsRegistrosSelecionados={lista_ids || idsRegistrosSelecionados}
									title='Inconsistências na Geração de Boletos'
									text='Verificamos que os cadastros abaixo encontram-se incompletos, verifique os campos (CPF/CNPJ, Endereço, Bairro, CEP, Unidade e UF)'
									form={{
										component: (
											<>
												{errors_validacao.map(({ tipo, nome, url, erros }) => (
													<Typography>
														{dict_tipos_linhas[tipo] || '?'}{' '}
														{url ? (
															<Link to={url} target='_blank'>
																{nome}
															</Link>
														) : (
															nome
														)}
														{` ${erros.join(', ')}`}.
													</Typography>
												))}
											</>
										),
									}}
								/>
							),
						}));
						return;
					}
				}
				handleClose();
				refresh();
			})
			.catch((e) => {
				const erroMsg = Object.values(e?.response?.data || {})[0] || [
					'Erro inesperado, tente recarregar a página',
				];
				notify(erroMsg, 'warning');
				handleClose();
			})
			.finally(() => {
				setLoadingRequest(false);
			});
	};

	const handleClose = () => {
		setModalValue((v) => ({ ...v, open: false }));
	};

	return configurar ? (
		<MenuItem
			disabled={!idsRegistrosSelecionados.length || disabled || !valid}
			onClick={() => {
				init();
				setModalValue((v) => ({
					...v,
					open: true,
					dialogBody: (
						<ModalConfiguracaoImpressao
							idsRegistrosSelecionados={idsRegistrosSelecionados}
							referencia={referencia}
							action='gerar'
						/>
					),
				}));
			}}
		>
			{label}
		</MenuItem>
	) : (
		<ButtonConfirmV2
			disabled={!idsRegistrosSelecionados.length || disabled || !valid || !boletoConfigIsValid}
			onClick={handleVerifyGeraBoleto}
		>
			{label}
		</ButtonConfirmV2>
	);
};

export default ButtonGerarBoletos;
