import React from 'react';
import { useNotify } from 'react-admin';

import { isMatch } from 'date-fns';

import { CondominiosContext } from 'context/CondominioContextProvider';
import { ModalSizeEditableContext } from 'components/common/ModalSizeEditableContext';
import { ButtonConfirmV2 } from 'components/common/buttons/ButtonConfirmV2';
import { ArrecadacoesContext } from '../../../ArrecadacoesContext';
import { BoletosContext } from '../../ContasReceberBoletos/BoletosContextProvider';
import { RemessasContext, useSegundaViaContext } from '../contextos';
import { useBaixarSegundaVia, useTratarResposta } from '../hooks';

export const ButtonGerarSegundaVia = ({ text = 'Gerar' }) => {
	const notify = useNotify();
	const { condominioSelecionado } = React.useContext(CondominiosContext);
	const { referencia, id_identificacao_arrecadacao, contaBDestinoId } = React.useContext(ArrecadacoesContext);
	const { idsRegistrosSelecionados } = React.useContext(RemessasContext);
	const {
		boletoConfigIsValid,
		opcoesImpressao,
		datasPrestacao,
		dataEnvioBackendDevedores,
		modeloDemonstrativo,
		modeloDemonstrativoVerso,
		comunicado,
		datasDemonstrativoMensal,
		dateRange,
		comunicadoId,
	} = React.useContext(BoletosContext);
	const { checkboxes, corrigirAteData, novaDataVencimento } = useSegundaViaContext();
	const { setModalValue } = React.useContext(ModalSizeEditableContext);
	const [loadingRequest, setLoadingRequest] = React.useState(false);
	const [valid, setValid] = React.useState(true);
	const modeloDemonstrativoId = modeloDemonstrativo;
	const parametrosPayloadExtras = React.useMemo(
		() => ({
			...opcoesImpressao,
			...datasPrestacao,
			...dataEnvioBackendDevedores,
			...datasDemonstrativoMensal,
			...checkboxes,
			modelo_demonstrativo: modeloDemonstrativoId,
			modelo_demonstrativo_verso: modeloDemonstrativoVerso,
			comunicado_text: comunicado,
			comunicado_id: comunicadoId,
			corrigir_ate_data: corrigirAteData,
			nova_data_vencimento: novaDataVencimento,
		}),
		[
			modeloDemonstrativoId,
			opcoesImpressao,
			datasPrestacao,
			dataEnvioBackendDevedores,
			comunicado,
			datasDemonstrativoMensal,
			comunicadoId,
		]
	);
	const tratarResposta = useTratarResposta({
		setModalValue,
		ButtonGerarRemessas: ButtonGerarSegundaVia,
	});
	const condominioSelecionadoId = condominioSelecionado?.id;

	const baixarRemessas = useBaixarSegundaVia({
		idsRegistrosSelecionados,
		referencia,
		id_identificacao_arrecadacao,
		contaBDestinoId,
		condominioSelecionadoId,
		onSuccessCallback: tratarResposta,
		parametrosPayloadExtras,
	});

	React.useEffect(() => {
		setValid(
			idsRegistrosSelecionados &&
				idsRegistrosSelecionados.length &&
				id_identificacao_arrecadacao &&
				contaBDestinoId &&
				condominioSelecionadoId &&
				referencia &&
				isMatch(referencia, 'yyyy-MM-dd') &&
				boletoConfigIsValid
		);
	}, [
		setValid,
		idsRegistrosSelecionados,
		id_identificacao_arrecadacao,
		contaBDestinoId,
		condominioSelecionadoId,
		referencia,
		boletoConfigIsValid,
	]);

	const handleClick = React.useCallback(async () => {
		setLoadingRequest(true);
		await baixarRemessas();
		setLoadingRequest(false);
	}, [setLoadingRequest, baixarRemessas]);

	const handleVerifyGeraRemessa = () => {
		if (comunicado && !opcoesImpressao.imprimir_comunicado) {
			notify('Você selecionou um Comunicado mas não selecionou Apresentar Comunicado', 'warning');
			return;
		}
		if (!comunicado && opcoesImpressao.imprimir_comunicado) {
			notify('Você selecionou Apresentar Comunicado mas não selecionou um Comunicado', 'warning');
			return;
		}
		if (
			modeloDemonstrativo?.id === 'DM' ||
			modeloDemonstrativo?.id === 'AM' ||
			(opcoesImpressao.imprimir_demonstrativo_verso && modeloDemonstrativoVerso?.id === 'DM')
		) {
			const startDate = new Date(dateRange.startDate);
			const endDate = new Date(dateRange.endDate);
			const diffInMonths =
				(endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth());
			if (diffInMonths > 2) {
				notify('Você não pode gerar o Demonstrativo Mensal com um período maior que 3 meses', 'warning');
				return;
			}
		}
		handleClick();
	};
	return (
		<ButtonConfirmV2 onClick={handleVerifyGeraRemessa} disabled={loadingRequest || !valid}>
			{text}
		</ButtonConfirmV2>
	);
};

export default ButtonGerarSegundaVia;
