import React from 'react';

import { Divider } from '@material-ui/core';

import { ModalSizeEditableContext, CustomDialogBodySizeEditable } from 'components/common/ModalSizeEditableContext';
import { ButtonCancel } from 'components/common/buttons/ButtonCancel';
import EmailTemplate from 'components/common/EmailTemplate/EmailTemplate';

import BoxConfigGeracaoBoleto from '../../../../BoxConfigGeracaoBoleto';
import { ButtonBaixarBoletos, ButtonEnviarBoletos, ButtonGerarBoletos } from '../../botoes';
import BoxModeloImpressaoArquivoUnico from './BoxModeloImpressaoArquivoUnico';
import BoxUploadMultipleFiles from './BoxUploadMultipleFiles';
import BoletosContextProvider from '../../BoletosContextProvider';

const titlesModalConfiguracaoImpressao = {
	baixar: 'Configurações do Boleto',
	enviar: 'Envio de Boleto',
	gerar: 'Configurações do Boleto',
};

export const ModalConfiguracaoImpressao = ({ idsRegistrosSelecionados, action }) => {
	const { setModalValue } = React.useContext(ModalSizeEditableContext);
	const [modeloImpressaoArquivoUnico, setModeloImpressaoArquivoUnico] = React.useState(0);
	const [loadingRequest, setLoadingRequest] = React.useState(false);
	const [mensagemJSON, setMensagemJSON] = React.useState([]);
	const [assunto, setAssunto] = React.useState('');
	const [{ anexosValidos, anexos }, setAnexosDados] = React.useState({
		anexosValidos: true,
		anexos: [],
	});

	const handleSaveEmail = (data, assuntoEmail) => {
		const { blocks } = JSON.parse(data);
		if (blocks[0].text !== '' || assuntoEmail !== '') {
			const lines = blocks.map((item) => {
				const inlineStyleRanges = item.inlineStyleRanges.sort((a, b) => {
					const limitA = a.offset + a.length;
					const limitB = b.offset + b.length;

					if (limitA === limitB) {
						return a.offset <= b.offset ? -1 : 1;
					}
					return limitA <= limitB ? 1 : -1;
				});
				return { ...item, inlineStyleRanges };
			});
			setMensagemJSON(lines);
			setAssunto(assuntoEmail);
		}
	};

	return (
		<BoletosContextProvider>
			<CustomDialogBodySizeEditable
				title={titlesModalConfiguracaoImpressao[action]}
				form={{
					component: (
						<>
							{action === 'baixar' && [
								<BoxModeloImpressaoArquivoUnico
									key='BoxModeloImpressaoArquivoUnico'
									{...{
										setModeloImpressaoArquivoUnico,
										modeloImpressaoArquivoUnico,
									}}
								/>,
							]}
							{action === 'enviar' && [
								<EmailTemplate handleSave={handleSaveEmail} key='EmailTemplate' />,
								<Divider
									key='DividerEmailTemplate'
									variant='middle'
									style={{ marginBottom: '20px' }}
								/>,
								<BoxUploadMultipleFiles
									setAnexosDados={setAnexosDados}
									anexosValidos={anexosValidos}
									key='BoxUploadMultipleFilesEmailTemplate'
								/>,
							]}
							{action === 'gerar' && <BoxConfigGeracaoBoleto />}
						</>
					),
				}}
				customActions={
					<>
						<ButtonCancel onClick={() => setModalValue((v) => ({ ...v, open: false }))} />
						{action === 'baixar' && (
							<ButtonBaixarBoletos
								label='Baixar'
								idsRegistrosSelecionados={idsRegistrosSelecionados}
								configuracao={{
									arquivo_unico: modeloImpressaoArquivoUnico,
								}}
								disabled={loadingRequest}
								setLoadingRequest={setLoadingRequest}
							/>
						)}
						{action === 'enviar' && (
							<ButtonEnviarBoletos
								label='Enviar'
								idsRegistrosSelecionados={idsRegistrosSelecionados}
								configuracao={{
									anexos: anexos,
									assunto: assunto,
									mensagem_json: mensagemJSON,
								}}
								disabled={loadingRequest || !anexosValidos}
								setLoadingRequest={setLoadingRequest}
							/>
						)}
						{action === 'gerar' && (
							<ButtonGerarBoletos
								label='Gerar'
								idsRegistrosSelecionados={idsRegistrosSelecionados}
								disabled={loadingRequest}
								setLoadingRequest={setLoadingRequest}
							/>
						)}
					</>
				}
			/>
		</BoletosContextProvider>
	);
};

export default ModalConfiguracaoImpressao;
