import memoize from 'components/common/memoize';

import { getFormatarOptions } from '../../../utils/functions';

import DefaultContaCorrenteProps from '../../default/ContaCorrenteProps';

import TabInformacao from '../../../tabs/TabInformacao';
import TabEmissaoBoletos from '../../../tabs/TabEmissaoBoletos';
import TabConta from './componentes/TabConta';
import TabInstrucoesCobrancaBoleto from './componentes/TabInstrucoesCobrancaBoleto';
import TabInstrucoesCobrancaRemessaAutomatico from './componentes/TabInstrucoesCobrancaRemessa';
import ModalCadastrarDadosIntegracaoAPI from './componentes/TabConta/ModalCadastrarDadosIntegracaoAPI';

import {
	createOnChangeTipoPadraoMulta,
	getPropsOnChangeTipoPadraoMulta,
} from './componentes/TabInstrucoesCobrancaRemessa/MultaFields';

import {
	createOnChangeTipoPadraoJuros,
	getPropsOnChangeTipoPadraoJuros,
} from './componentes/TabInstrucoesCobrancaRemessa/JurosFields';

import {
	createOnChangeTipoPadraoDesconto,
	getPropsOnChangeTipoPadraoDesconto,
} from './componentes/TabInstrucoesCobrancaRemessa/DescontoFields';

import { formatarInitialValuesAutomaticoOuManual as formatarInitialValues } from './formatarInitialValues';
import getFormataPayloadValues from './getFormataPayloudValues';
import getValidate from './getValidate';
import validacoes from './validacoes';
import formatadoresPayloadValues from './formatadoresPayloadValues';
import { getDadosFormattingContaCorrente } from '../../../utils/formaters_parses';

const getExtras = ({
	props,
	instituicaoCodigo,
	initialDados: {
		opcoesDadosBancarios: { integracoes },
	},
}: any) => ({
	optionsIsencoes: integracoes[instituicaoCodigo].optionsIsencoes,
	...props,
});

const getDisabledTabs = memoize(({ values: { emitir_boletos } }: any) => {
	return {
		disabled0: false,
		disabled1: false,
		disabled2: !emitir_boletos,
		disabled3: !emitir_boletos,
		disabled4: !emitir_boletos,
	};
});

const ContaCorrenteProps = {
	...DefaultContaCorrenteProps,
	instituicaoCodigo: '403',
	getOptions: getFormatarOptions('403'),
	getDisabledTabs,
	getValidate,
	tabContents: {
		0: {
			TabInformacao,
			TabEmissaoBoletos,
			TabConta,
			TabInstrucoesCobrancaRemessa: TabInstrucoesCobrancaRemessaAutomatico,
			TabInstrucoesCobrancaBoleto,
		},
	},
	validacoes: {
		0: validacoes,
	},
	props: {
		0: {
			tipo_conta: {
				source: 'tipo_conta_bancaria',
				name: 'tipo_conta_bancaria',
				id: 'tipo_conta_bancaria',
				choices: [{ id: 'CC', text: 'Conta Corrente' }],
			},
			conta_corrente: {
				...getDadosFormattingContaCorrente(21),
			},
			multa: {
				getPropsOnChangeTipo: getPropsOnChangeTipoPadraoMulta,
				createOnChangeTipo: createOnChangeTipoPadraoMulta,
			},
			juros: {
				getPropsOnChangeTipo: getPropsOnChangeTipoPadraoJuros,
				createOnChangeTipo: createOnChangeTipoPadraoJuros,
			},
			desconto: {
				getPropsOnChangeTipo: getPropsOnChangeTipoPadraoDesconto,
				createOnChangeTipo: createOnChangeTipoPadraoDesconto,
			},
			CadastrarDadosIntegracaoAPIButton: {
				ModalCadastrarDadosIntegracaoAPI,
				getInicialCallback: ({ errorsMsg, ...inicialDados }: any) => ({
					...inicialDados,
					errorsMsg,
					initialValues: {
						client_id: '',
						client_secret: '',
					},
					values: {
						client_id: '',
						client_secret: '',
					},
					errors: {
						client_id: errorsMsg.client_id.required,
						client_secret: errorsMsg.client_secret.required,
					},
				}),
			},
		},
	},
	getFormataPayloadValues,
	formatarInitialValues,
	formatadoresPayloadValues: {
		0: { ...formatadoresPayloadValues },
	},
	getExtras,
};

export default ContaCorrenteProps;
