import { getBeneficiarioDados, formataCampoComOption } from '../../default/ContaCorrenteProps/formatarInitialValues';
import formatarInitialValues from '../../default/ContaCorrenteProps/formatarInitialValues';
import { FormatarInitialValuesProps } from '../../../types';

const optionsEmpty = {
	choices: [],
	objects: {},
} as any;

const formatarInitialValuesAutomaticoOuManual = ({
	conta_condominio,
	cobranca_config,
	options: {
		agencia: optionsAgencia = optionsEmpty,
		id_inst_financeira: { objects: instituicoesObj },
		...options
	},
	condominioRecord,
	dadosAdministradora,
}: FormatarInitialValuesProps) => {
	if (conta_condominio.tipo_integracao == 1) {
		const formatarInitialValuesManual = formatarInitialValues({
			conta_condominio,
			cobranca_config,
			options: {
				agencia: optionsAgencia,
				id_inst_financeira: { objects: instituicoesObj },
				...options,
			},
			condominioRecord,
			dadosAdministradora,
		});
		return formatarInitialValuesManual;
	}

	const values = { ...conta_condominio, ...cobranca_config };

	const conta_corrente = conta_condominio.conta_corrente_cv
		? `${conta_condominio.conta_corrente}-${conta_condominio.conta_corrente_cv}`
		: conta_condominio.conta_corrente;

	return {
		// Tab Informações
		...conta_condominio,
		id_inst_financeira: instituicoesObj[conta_condominio.id_inst_financeira],

		// Tab Emissão de Boletos
		tipo_integracao: formataCampoComOption({
			field: 'tipo_integracao',
			options,
			values,
		}),
		...getBeneficiarioDados({ cobranca_config, condominioRecord, options, dadosAdministradora }),

		// Tab Conta
		agencia: null,
		conta_corrente: conta_corrente,
		nosso_numero: cobranca_config.nosso_numero || 0,
		dados_integracao_api_expires_at: conta_condominio?.dados_integracao_api_expires_at || null,
		carteira: formataCampoComOption({
			field: 'carteira',
			options,
			values,
		}),
		especie_titulo: formataCampoComOption({
			field: 'especie_titulo',
			options,
			values,
		}),
		aceite: null,
		moeda: null,
		conta_cedente: cobranca_config.conta_cedente,
		emissao_boleto: null,
		distribuicao_boleto: null,
		sequencial_arquivo: 0,

		local_pagamento: '',

		// Tab de Instruções de Cobrança Remessa
		multa_tipo: formataCampoComOption({
			field: 'multa_tipo',
			options,
			values,
		}),
		multa_valor: cobranca_config.multa_valor,
		multa_dias: cobranca_config.multa_dias || null,

		juros_tipo: formataCampoComOption({
			field: 'juros_tipo',
			options,
			values,
		}),
		juros_valor: cobranca_config.juros_valor,
		juros_dias: null,

		desconto_tipo: formataCampoComOption({
			field: 'desconto_tipo',
			options,
			values,
		}),
		desconto_valor: cobranca_config.desconto_valor,
		desconto_dias: cobranca_config.desconto_dias,

		limite_pagamento_tipo: null,
		limite_pagamento_dias: null,

		protesto_tipo: null,
		protesto_dias: null,

		sacador_avalista: null,

		// Tab de Instruções de Cobrança Boleto
		linha_1: cobranca_config.linha_1 || '',
		linha_2: cobranca_config.linha_2 || '',
		linha_3: cobranca_config.linha_3 || '',
		linha_4: '',
		linha_5: '',
	};
};

export default formatarInitialValuesAutomaticoOuManual;
