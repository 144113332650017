import React from 'react';
import { useNotify } from 'react-admin';

import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';

import { ButtonCancel } from 'components/common/buttons/ButtonCancel';
import { ButtonGerarRemessas } from '../botoes';

import BoxConfigGeracaoBoleto from '../../../BoxConfigGeracaoBoleto';
import { ModalConfirmarGerarBoletoAutomatico } from './ModalConfirmarGerarBoletoAutomatico';
import { ModalSizeEditableContext, CustomDialogBodySizeEditable } from 'components/common/ModalSizeEditableContext';
import BoletosContextProvider, { BoletosContext } from '../../ContasReceberBoletos/BoletosContextProvider';

import { IModalValue } from 'types/tpyesGlobal';

export type DataItem = {
	condominio?: number | null;
	correcao_monetaria?: number | null;
	data_pagamento?: string | null;
	data_vencimento?: string | null;
	desconto?: number | null;
	id?: number | null;
	identificacao?: number | null;
	juros?: number | null;
	multa?: number | null;
	nome_conta?: string | null;
	nome_grupo_unidade?: string | null;
	nome_tipo_unidade?: string | null;
	nome_unidade?: string | null;
	nosso_numero?: string | null;
	numero_parcelas?: number | null;
	referencia?: string | null;
	responsavel?: string | null;
	situacao?: string | null;
	tipo_pagamento?: string | null;
	tipo_responsavel?: string | null;
	valor_base?: number | null;
	valor_pendente?: number | null;
	valor_recebido?: number | null;
};

type ModalConfiguracaoImpressaoBancosAutomaticosProps = {
	data?: DataItem[];
	nomeBanco: string;
};

type ButtonOpenModalProps = {
	nomeBanco: string;
};

const ButtonOpenModal: React.FC<ButtonOpenModalProps> = ({ nomeBanco }) => {
	const notify = useNotify();
	const { setModalValue } = React.useContext(ModalSizeEditableContext);
	const { opcoesImpressao, modeloDemonstrativo, comunicado, dateRange, modeloDemonstrativoVerso } =
		React.useContext(BoletosContext);

	const handleVerifyGeraBoleto = () => {
		if (comunicado && !opcoesImpressao.imprimir_comunicado) {
			notify('Você selecionou um Comunicado mas não selecionou Apresentar Comunicado', 'warning');
			return;
		}
		if (!comunicado && opcoesImpressao.imprimir_comunicado) {
			notify('Você selecionou Apresentar Comunicado mas não selecionou um Comunicado', 'warning');
			return;
		}
		if (
			modeloDemonstrativo?.id === 'DM' ||
			modeloDemonstrativo?.id === 'AM' ||
			(opcoesImpressao.imprimir_demonstrativo_verso && modeloDemonstrativoVerso?.id === 'DM')
		) {
			const startDate = new Date(dateRange.startDate);
			const endDate = new Date(dateRange.endDate);
			const diffInMonths =
				(endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth());
			if (diffInMonths > 2) {
				notify('Você não pode gerar o Demonstrativo Mensal com um período maior que 3 meses', 'warning');
				return;
			}
		}
		return setModalValue((v: IModalValue) => ({
			...v,
			open: true,
			dialogBody: <ModalConfirmarGerarBoletoAutomatico nomeBanco={nomeBanco} />,
		}));
	};

	return (
		<Button size='small' color='primary' onClick={handleVerifyGeraBoleto} startIcon={<SaveIcon />}>
			Gerar
		</Button>
	);
};

export const ModalConfiguracaoImpressaoBancosAutomaticos: React.FC<
	ModalConfiguracaoImpressaoBancosAutomaticosProps
> = ({ data, nomeBanco }) => {
	const { setModalValue } = React.useContext(ModalSizeEditableContext);
	const { init } = React.useContext(BoletosContext);

	React.useEffect(init, []);

	return (
		<BoletosContextProvider>
			<CustomDialogBodySizeEditable
				title='Configurações do Boleto'
				form={{
					component: <BoxConfigGeracaoBoleto />,
				}}
				customActions={
					<>
						<ButtonCancel
							id={undefined}
							className={undefined}
							onClick={() => setModalValue((v: IModalValue) => ({ ...v, open: false }))}
						>
							Cancelar
						</ButtonCancel>
						{data ? <ButtonOpenModal nomeBanco={nomeBanco} /> : <ButtonGerarRemessas />}
					</>
				}
			/>
		</BoletosContextProvider>
	);
};

export default ModalConfiguracaoImpressaoBancosAutomaticos;
