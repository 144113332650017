import React, { useCallback, useContext, useEffect, useState } from 'react';

import { useDataProvider, useNotify, useRefresh, TextInput, required, useListContext, useInput } from 'react-admin';

import { Form, Field } from 'react-final-form';

import Box from '@material-ui/core/Box';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Typography from '@material-ui/core/Typography';
import ListAltIcon from '@material-ui/icons/ListAlt';

import {
	CustomDialogBodySizeEditable,
	ModalSizeEditableContext,
	ModalSizeEditableContextProvider,
} from '../../common/ModalSizeEditableContext';
import { ButtonCancel } from '../../common/buttons/ButtonCancel';
import { ButtonConfirm } from '../../common/buttons/ButtonConfirm';
import { FinalFormCurrencyInput } from '../../common/CurrencyInput';
import { SyndikosMuiPickersUtilsProvider } from '../../common/InputDate';
import { SyndikosSelect } from '../../common/SyndikosSelect';
import VeryHighLimitReferenceInput from '../../common/VeryHighLimitReferenceInput';
import TabelaRecebimentosPagos from './TabelaRecebimentosPagos';

const regexIsoDate = /(^\d*)-(\d*)-(\d*)/;

const createHistoricoDefault = (
	{ nome_unidade, tipo_unidade, grupo_unidade, data_vencimento, referencia, nome_identificacao, responsavel },
	descBaixa
) => {
	const verifyResponsavel = responsavel ? responsavel : 'Sem Responsável';
	const formattedDescBaixa = descBaixa
		? descBaixa
				.replaceAll('{{nome_unidade}}', nome_unidade)
				.replaceAll('{{tipo_unidade}}', tipo_unidade || '')
				.replaceAll('{{grupo_unidade}}', grupo_unidade || '')
				.replaceAll('{{data_vencimento}}', data_vencimento.split('T')[0].replace(regexIsoDate, '$3/$2/$1'))
				.replaceAll('{{data_referencia}}', referencia.split('T')[0].replace(regexIsoDate, '$2/$1'))
				.replaceAll('{{nome_identificacao}}', nome_identificacao)
				.replaceAll('{{responsavel_nome}}', verifyResponsavel)
		: '';

	return formattedDescBaixa;
};

const IdentificadorContaReceber = ({ textoIdentificadorConta = '' } = {}) => (
	<Typography>{textoIdentificadorConta}</Typography>
);

const dateValid =
	(msg = 'Data inválida') =>
	(value) => {
		if (!value || value.toString() === 'Invalid Date') return msg;
		return undefined;
	};

const maxDateLimited =
	({ maxDate = new Date(), msg = 'Data fornecida ultrapassou data máxima limite' }) =>
	(value) => {
		maxDate.setHours(0, 0, 0, 0);
		value.setHours(0, 0, 0, 0);
		if (value > maxDate) return msg;
		return undefined;
	};

const DataPagamentoInput = () => (
	<Field
		name='data_pagamento'
		id='data_pagamento'
		validate={(value, allValues, meta) => {
			let error = undefined;
			error = required('Data de pagamento requerida')(value, allValues, meta);
			if (!error) error = dateValid()(value, allValues, meta);
			if (!error)
				error = maxDateLimited({ msg: 'A conta deve ser paga no máximo na data atual' })(
					value,
					allValues,
					meta
				);
			return error;
		}}
	>
		{({ input, meta: { error } }) => (
			<SyndikosMuiPickersUtilsProvider>
				<KeyboardDatePicker
					label='Data de Pagamento'
					format='dd/MM/yyyy'
					mask='__/__/____'
					maxDate={new Date()}
					margin='dense'
					variant='inline'
					inputVariant='outlined'
					KeyboardButtonProps={{
						'aria-label': 'change date',
					}}
					value={input.value}
					name={input.name}
					onChange={input.onChange}
					error={error}
					helperText={error}
					fullWidth
					disableToolbar
				/>
			</SyndikosMuiPickersUtilsProvider>
		)}
	</Field>
);

const DataVencimentoInput = () => (
	<Field name='data_vencimento' id='data_vencimento'>
		{({ input }) => (
			<SyndikosMuiPickersUtilsProvider>
				<KeyboardDatePicker
					label='Data de Vencimento'
					format='dd/MM/yyyy'
					mask='__/__/____'
					margin='dense'
					variant='inline'
					inputVariant='outlined'
					KeyboardButtonProps={{
						'aria-label': 'change date',
					}}
					value={input.value}
					name={input.name}
					disabled={true}
					fullWidth
					disableToolbar
				/>
			</SyndikosMuiPickersUtilsProvider>
		)}
	</Field>
);

const FieldsInformacoesPagamento = ({ lote, values: { condominio_id } }) => (
	<Box display='flex' gridGap='5px'>
		<VeryHighLimitReferenceInput
			label='Conta Bancária'
			source='conta_b_destino'
			reference='conta_condominio'
			variant='outlined'
			filter={{
				id_condominio: condominio_id,
				situacao: 'A',
				tipo_conta_expandido: 'CC',
			}}
			fullWidth
		>
			<SyndikosSelect optionText='nome_conta' variant='outlined' allowEmpty={false} disableClearable fullWidth />
		</VeryHighLimitReferenceInput>
		{lote ? <DataPagamentoInput /> : <DataVencimentoInput />}
		<FinalFormCurrencyInput
			id='valor_pendente'
			name='valor_pendente'
			label='Pendente'
			margin='dense'
			prefix='R$ '
			decimalScale={2}
			disabled={true}
			fullWidth={true}
		/>
	</Box>
);

const FieldsPagamentoSingle = ({ values: { valor_pendente } }) => (
	<Box display='flex' gridGap='5px'>
		<DataPagamentoInput />
		<FinalFormCurrencyInput
			id='valor'
			name='valor'
			label='Valor'
			margin='dense'
			prefix='R$ '
			decimalScale={2}
			onChange={(event) => {
				if (!event.target.value) event.target.value = 0;
				if (event.target.value > valor_pendente) event.target.value = valor_pendente;
			}}
			fullWidth={true}
		/>
	</Box>
);

const FieldsDMJ = ({ values: { valor, desconto } }) => {
	const { input: inputDesconto } = useInput({ source: 'desconto' });
	const updateDesconto = useCallback(() => {
		if (inputDesconto?.name && desconto > valor) inputDesconto.onChange({ target: { value: valor } });
	}, [inputDesconto, desconto, valor]);
	useEffect(updateDesconto, [valor]);

	return (
		<Box display='flex' gridGap='5px'>
			<FinalFormCurrencyInput
				id='desconto'
				name='desconto'
				label='Desconto'
				margin='dense'
				prefix='R$ '
				decimalScale={2}
				onChange={(event) => {
					if (!event.target.value) event.target.value = 0;
					if (event.target.value > valor) event.target.value = valor;
					if (event.target.value > 999999999999.99) event.target.value = 999999999999.99;
				}}
				fullWidth={true}
			/>
			<FinalFormCurrencyInput
				id='multa'
				name='multa'
				label='Multa'
				margin='dense'
				prefix='R$ '
				decimalScale={2}
				onChange={(event) => {
					if (!event.target.value) event.target.value = 0;
					if (event.target.value > 999999999999.99) event.target.value = 999999999999.99;
				}}
				fullWidth={true}
			/>
			<FinalFormCurrencyInput
				id='juros'
				name='juros'
				label='Juros'
				margin='dense'
				prefix='R$ '
				decimalScale={2}
				onChange={(event) => {
					if (!event.target.value) event.target.value = 0;
					if (event.target.value > 999999999999.99) event.target.value = 999999999999.99;
				}}
				fullWidth={true}
			/>
			<FinalFormCurrencyInput
				id='correcao_monetaria'
				name='correcao_monetaria'
				label='Correção Monetária'
				margin='dense'
				prefix='R$ '
				decimalScale={2}
				onChange={(event) => {
					if (!event.target.value) event.target.value = 0;
					if (event.target.value > 999999999999.99) event.target.value = 999999999999.99;
				}}
				fullWidth={true}
			/>
		</Box>
	);
};

const InputHistorico = (props) => (
	<Field name='historico' id='historico'>
		{({ input }) => {
			const historicoDefault = !input.value ? (props.historicoDefault ? `- ${props.historicoDefault}` : '') : '';
			return (
				<TextInput
					value={(input.value || '').slice(0, 200)}
					name={input.name}
					label='Observação de Baixa'
					variant='outlined'
					helperText={`${(input.value || '').length}/200 ${historicoDefault}`}
					fullWidth
				/>
			);
		}}
	</Field>
);

const FieldsPagamento = ({ lote, ...extras }) => (
	<Box display='flex' flexDirection='column' gridGap='10px'>
		{!lote && <IdentificadorContaReceber {...extras} />}
		<FieldsInformacoesPagamento lote={lote} {...extras} />
		{!lote && <FieldsPagamentoSingle {...extras} />}
		{!lote && <FieldsDMJ {...extras} />}
		<InputHistorico {...extras} />
	</Box>
);

const ButtonModalTabelaRecebimentosPagos = ({ recebimentosPagos }) => {
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const closeModal = useCallback(() => {
		setModalValue((v) => ({ ...v, open: false }));
	}, [setModalValue]);
	return (
		<ButtonConfirm
			startIcon={<ListAltIcon />}
			onClick={() => {
				setModalValue((v) => ({
					...v,
					open: true,
					dialogBody: (
						<CustomDialogBodySizeEditable
							title='Baixas Realizadas'
							customActions={<ButtonCancel onClick={closeModal}>Fechar</ButtonCancel>}
							form={{
								handleSubmit: () => {},
								component: <TabelaRecebimentosPagos recebimentosSelecionados={recebimentosPagos} />,
							}}
						/>
					),
				}));
			}}
		>
			Baixas Realizadas
		</ButtonConfirm>
	);
};

export const ModalPagamentoLote = ({ contas_receber }) => {
	const { data } = useListContext();
	const [contaBDestino] = useState(contas_receber[0].conta_b_destino_id);
	const [valor_pendente] = useState(
		contas_receber.reduce((total, { recebimentos_manuais }) => {
			const valor_pendente = recebimentos_manuais?.[0]?.valor_pendente || 0;
			return parseFloat((total + valor_pendente).toFixed(2));
		}, 0)
	);
	const dP = useDataProvider();
	const notify = useNotify();
	const refresh = useRefresh();
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const [loading, setLoading] = useState(false);

	const [descBaixa, setDescBaixa] = useState();

	useEffect(() => {
		const auth = JSON.parse(localStorage.getItem('auth') || '');
		dP.getOne('administradora', {
			id: auth.tenant.schema_name,
		}).then((response) => {
			setDescBaixa(response.data.descricao_baixa);
		});
	}, []);

	const handleSubmit = ({ data_pagamento, historico, conta_b_destino }) => {
		if (loading) return;
		setLoading(true);

		const dictUpdateBase = {
			conta_b_destino: conta_b_destino,
			data_movimentacao: data_pagamento.toISOString().split('T')[0],
			desconto: 0,
			juros: 0,
			multa: 0,
			correcao_monetaria: 0,
		};
		dP.updateMany(
			'pagamento_contas_receber',
			contas_receber.map(({ recebimentos_manuais, ...contaR }) => ({
				id: recebimentos_manuais?.[0]?.id,
				data: {
					id: recebimentos_manuais?.[0]?.id,
					historico: historico || createHistoricoDefault(contaR, descBaixa),
					valor: recebimentos_manuais?.[0]?.valor_pendente,
					...dictUpdateBase,
				},
			}))
		)
			.then((response) => {
				const responses = response.data || [];
				const firstRejectedResponse = responses.find((r) => r?.status === 'rejected');
				if (firstRejectedResponse) return Promise.reject(firstRejectedResponse?.reason);
				notify('Pagamento registrado com sucesso!');
				refresh();
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
			})
			.finally(() => setLoading(false));
	};

	const closeModal = () => setModalValue((v) => ({ ...v, open: false }));

	return (
		<Form
			onSubmit={(e) => {
				e.preventDefault();
			}}
			initialValues={{
				condominio_id: data[Object.keys(data)[0]].condominio,
				conta_b_destino: contaBDestino,
				valor_pendente: valor_pendente,
				valor: valor_pendente,
				data_pagamento: new Date(),
				historico: '',
			}}
			render={({ values, invalid }) => (
				<CustomDialogBodySizeEditable
					title='Registrar Recebimento - Conta a Receber - Lote'
					form={{
						component: <FieldsPagamento lote={true} historicoDefault={descBaixa} values={values} />,
					}}
					customActions={
						<>
							<ButtonCancel onClick={closeModal} />
							<ButtonConfirm disabled={invalid || loading} onClick={() => handleSubmit(values)} />
						</>
					}
				/>
			)}
		/>
	);
};

const getTipoResponsavel = (tipo_responsavel) =>
	tipo_responsavel === 'P' ? 'Proprietário' : tipo_responsavel === 'I' ? 'Inquilino' : tipo_responsavel;

const getTextoIdentificadorConta = ({ tipo_unidade, nome_unidade, tipo_responsavel, responsavel }) =>
	`Tipo da Unidade: ${tipo_unidade} - ${nome_unidade} - ${getTipoResponsavel(tipo_responsavel)}: ${responsavel}`;

const formatarDadosFixosModalPagamentoSingle = (conta_receber, descBaixa) => ({
	id: conta_receber.recebimentos_a_pagar[0]?.id,
	conta_b_destino_id: conta_receber.conta_b_destino_id,
	historicoDefault: createHistoricoDefault(conta_receber, descBaixa),
	textoIdentificadorConta: getTextoIdentificadorConta(conta_receber),
	data_pagamento: new Date(),
	data_vencimento: new Date(`${conta_receber.data_vencimento}T10:00`),
	valor_pendente: conta_receber.valor_pendente_total,
	recebimentosAPagarIds: conta_receber.recebimentos_a_pagar.slice(1).map((r) => r.id),
	recebimentosPagos: conta_receber.recebimentos_pagos,
});

export const ModalPagamentoSingle = ({ conta_receber }) => {
	const dP = useDataProvider();
	const notify = useNotify();
	const refresh = useRefresh();
	const { data } = useListContext();
	const { setModalValue } = useContext(ModalSizeEditableContext);
	const [descBaixa, setDescBaixa] = useState();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const auth = JSON.parse(localStorage.getItem('auth') || '');
		dP.getOne('administradora', {
			id: auth.tenant.schema_name,
		}).then((response) => {
			setDescBaixa(response.data.descricao_baixa);
		});
	}, []);

	const [
		{
			id,
			conta_b_destino_id,
			historicoDefault,
			textoIdentificadorConta,
			recebimentosAPagarIds,
			recebimentosPagos,
			valor_pendente,
			data_pagamento,
			data_vencimento,
		},
		setDadosFixos,
	] = useState(formatarDadosFixosModalPagamentoSingle(conta_receber, descBaixa));

	useEffect(() => {
		setDadosFixos(formatarDadosFixosModalPagamentoSingle(conta_receber, descBaixa));
	}, [descBaixa]);

	const handleSubmit = ({
		data_pagamento,
		desconto,
		correcao_monetaria,
		juros,
		multa,
		historico,
		conta_b_destino,
		valor,
	}) => {
		if (loading) return;
		setLoading(true);

		if (!id) {
			notify('Não foi encontrado recebimentos a serem pagos, tente recarregar a página', 'warning');
			return;
		}

		dP.updateMany('pagamento_contas_receber', [
			{
				id,
				data: {
					id,
					data_movimentacao: data_pagamento.toISOString().split('T')[0],
					desconto: `${desconto}`,
					correcao_monetaria: `${correcao_monetaria}`,
					juros: `${juros}`,
					multa: `${multa}`,
					historico: historico || createHistoricoDefault(conta_receber, descBaixa),
					conta_b_destino: conta_b_destino,
					valor: `${valor}`,
					valor_pendente: `${valor}`,
					recebimentos_manuais_subordinados_a_pagar_ids: recebimentosAPagarIds,
				},
			},
		])
			.then((response) => {
				const responses = response.data || [];
				const firstRejectedResponse = responses.find((r) => r?.status === 'rejected');
				if (firstRejectedResponse) return Promise.reject(firstRejectedResponse?.reason);
				notify('Pagamento registrado com sucesso!');
				refresh();
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				const erroMsg = Object.values(e?.response?.data || {})[0] || [
					'Erro inesperado, tente recarregar a página',
				];
				notify(erroMsg, 'warning');
				refresh();
			})
			.finally(() => setLoading(false));
	};

	const closeModal = () => setModalValue((v) => ({ ...v, open: false }));

	return (
		<Form
			onSubmit={(e) => {
				e.preventDefault();
			}}
			initialValues={{
				condominio_id: data[Object.keys(data)[0]].condominio,
				conta_b_destino: conta_b_destino_id,
				correcao_monetaria: 0,
				data_pagamento: data_pagamento,
				data_vencimento: data_vencimento,
				desconto: 0,
				historico: '',
				id: id,
				juros: 0,
				multa: 0,
				valor_pendente: valor_pendente,
				valor: valor_pendente,
			}}
			render={({ values, invalid }) => (
				<CustomDialogBodySizeEditable
					title='Registrar Recebimento - Conta a Receber'
					form={{
						component: (
							<FieldsPagamento
								textoIdentificadorConta={textoIdentificadorConta}
								historicoDefault={historicoDefault}
								values={values}
							/>
						),
					}}
					customActions={
						<>
							{!!recebimentosPagos.length && recebimentosPagos.length > 0 && (
								<ModalSizeEditableContextProvider customDialogProps={{ minWidth: '90vw' }}>
									<ButtonModalTabelaRecebimentosPagos recebimentosPagos={recebimentosPagos} />
								</ModalSizeEditableContextProvider>
							)}
							<ButtonCancel onClick={closeModal} />
							<ButtonConfirm disabled={invalid || loading} onClick={() => handleSubmit(values)} />
						</>
					}
				/>
			)}
		/>
	);
};
