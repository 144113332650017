import React from 'react';

import { Box } from '@material-ui/core';

import CadastrarDadosIntegracaoAPIField from '../../../../../commons/CadastrarDadosIntegracaoAPIField';

import {
	CarteiraField,
	ImprimirPixField,
	NossoNumeroField,
	ContaCedenteField,
	EspecieTituloField,
	ContaCorrenteField,
} from '../../../../../tabs/TabConta';

const TabConta = () => (
	<Box>
		<Box>
			<Box display='flex' flexDirection='row'>
				<Box>
					<ContaCorrenteField />
				</Box>
				<ContaCedenteField />
				<EspecieTituloField />
				<CarteiraField />
				<Box ml={1}>
					<NossoNumeroField />
				</Box>
			</Box>
			<Box>
				<ImprimirPixField instituicao_label='Banco do Brasil' />
			</Box>
			<Box gridColumnGap='0.5rem' display='flex' alignSelf='baseline' marginTop='6px' marginBottom='4px'>
				<CadastrarDadosIntegracaoAPIField />
			</Box>
		</Box>
	</Box>
);

export default TabConta;
